.contentsForgot {
}

.contentsForgot p {
  margin: 32px 0 60px 0;
  font-size: 16px;
  letter-spacing: 1px;
}

.contentsForgot .button {
  margin: 17px auto 0;
}

.contentsForgot .inputBox {
  width: 100%;
  padding-bottom: 32px;
}

.contentsForgot .inputBoxes {
  width: 800px;
  grid-template-columns: 1fr 460px;
  margin-bottom: 20px;
  height: 175px;
  display: grid;
  align-content: space-between;
}

.contentsForgot .inputBoxes label {
  text-align: right;
  flex-grow: 1;
  padding-right: 52px;
  align-self: center;
}

.contentsForgot .inputBoxes .input1Line {
}

.contentsForgot .backToHome{
  margin: 0 auto;
  letter-spacing: 1px;
  font-size: 18px;
  text-decoration: none !important;
  border-bottom: 1px solid #4BA7C6;
}

#container.contentsForgot a {
  color: #64C8D2 !important;
}

#container.contentsForgot a.backToHome{
  color: #4BA7C6 !important;
}