.chatTitle {
  position: fixed;
  top:0;
  right: 0;
  width: calc(100% - 274px);
  background-color: white;
  height:87px;
  box-sizing: border-box;
  font-size: 22px;
  display: flex;
  white-space: nowrap;
  color:#006181;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px 0 17px;
  border-bottom: 1px solid #D0DCE0;
  background: #F5F8F8;
}

.contsAssetDetailList .chatTitle {
  position: relative;
  top: auto;
  right: auto;
}

.chatTitle::after{
  content: '';
  width: 100%;
  height: 1px;
  background-color: white;
  position: absolute;
  bottom: -2px;
  left: 0;
}

.chatTitleL{
  display: flex;
  align-items: center;
  height:87px;
}

.chatTitle .buttonBack{
  padding:0 20px 0 0;
}
.contsAssetDetailList .chatTitle .buttonBack{
  position: absolute;
  top:30px;
  left:17px;
}
.contsAssetDetailList .chatTitle h3{
  padding-left: 40px;
}
.contsAssetDetailList .chatTitle > a:last-child{
  width:187px;
  height:38px;
  background: #006181;
  border-radius: 19px;
  color: white !important;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 100%;
  margin: 0 !important;
  padding: 0 !important;
  text-decoration: none !important;
  text-align: center;
}

.chatTitleDetail{
  width: 187px;
  height: 38px;
  background: #006181;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 19px;
  color: white !important;
  text-decoration: none !important;
  font-size: 16px;
}

/*
.chatTitle {
  position: fixed;
  top: 120px;
  left: 350px;
  right: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  border-radius: 27px;
  background: #f8f8f8;
  padding: 16px 36px;
  box-sizing: border-box;
  margin: -15px -15px 13px;
}
*/

.chatTitle h3 {
  /*display: flex;
  justify-content: center;
  align-items: center;
  min-width: 140px;
  border-radius: 10px;
  background: #fff;
  border: 1px solid #707070;
  padding: 8px;
  box-sizing: border-box;
  font-size: 18px;
  font-weight: bold;
  margin-right: 39px;*/
}

.chatTitle .listRequestHeader {
  width: auto;
}

.chatTitle .listRequestHeader th {
  padding: 0;
  width: auto;
  font-size: 14px;
  font-weight: normal;
  color: #A0A0A0;
}

.chatTitle .listRequestHeader td {
  padding: 0 80px 0 28px;
  width: auto;
}

.chatTitle .listRequestHeader td:last-child {
  padding-right: 0;
}
