.sortBy{
  padding-bottom: 40px;
}

#container .searchSortBody .sortBy{
  padding-bottom: 23px;
}

.sortBy h6{
  font-size: 14px;
  color: #64C8D2;
  margin-bottom: 5px;
  display: block;
}

.sortBy ul{
  display: flex;
  margin: 0;
  padding: 0;
}

.sortBy ul li{
  width: 150px;
  height: 42px;
  background-color: white;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #64C8D2;
}

.sortBy ul li:first-child{
  border-radius: 21px 0 0 21px;
}

.sortBy ul li:last-child{
  border-radius: 0 21px 21px 0;
}

#container .sortBy ul li.listOrder.selected{
  background-color: #64C8D2;
}