.inputSelectLabel span{
  font-size: 14px;
  color: #64C8D2;
  margin-bottom: 5px;
  display: block;
}

.inputSelect{
  margin: 9px 50px 9px 0;
}

.inputSelect > div{
  border-radius: 10px;
  cursor: pointer;
  line-height: 1;
  border-color: #999;
  box-sizing: border-box;
  font-size: 16px;
  color: #333;
}

.css-1rhbuit-multiValue div:first-child{
  font-size: 100%;
}

.inputSelect > div:hover,.inputSelect > div:active{
  border-color: initial;
}

.inputSelect.default {
  color: gray;
}


.inputSelect{
  margin: 0 15px 0 0;
}
.inputSelect > div:first-of-type{
  position: relative;
  background: #fff;
  border-radius: 30px;
  padding: 3px 13px 2px 20px;;
  color: #333;
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: #D6D6D6;
  border: none;
  box-sizing: border-box;
}

.inputSelect > div:first-of-type:after {
  content: "";
  position: absolute;
  right: 20px;
  top: 45%;
  width: 10px;
  height: 10px;
  border-top: 2px solid #64C8D2;
  border-left: 2px solid #64C8D2;
  transform: translateY(-50%) rotate(-135deg);
  pointer-events: none;
}
.inputSelect > div:first-of-type > div:last-of-type > div:last-of-type {
  opacity: 0;
}
#react-select-0-listbox,#react-select-1-listbox,#react-select-2-listbox,#react-select-3-listbox,
#react-select-4-listbox,#react-select-5-listbox,#react-select-6-listbox,#react-select-7-listbox,
#react-select-8-listbox,#react-select-9-listbox,#react-select-10-listbox,#react-select-11-listbox,
#react-select-12-listbox,#react-select-13-listbox,#react-select-14-listbox,#react-select-15-listbox,
#react-select-16-listbox,#react-select-17-listbox,#react-select-18-listbox,#react-select-19-listbox
{
  padding: 4px 0;
}

#react-select-0-placeholder,#react-select-1-placeholder,#react-select-2-placeholder,#react-select-3-placeholder,
#react-select-4-placeholder,#react-select-5-placeholder,#react-select-6-placeholder,#react-select-7-placeholder,
#react-select-8-placeholder,#react-select-9-placeholder,#react-select-10-placeholder,#react-select-11-placeholder,
#react-select-12-placeholder,#react-select-13-placeholder,#react-select-14-placeholder,#react-select-15-placeholder,
#react-select-16-placeholder,#react-select-17-placeholder,#react-select-18-placeholder,#react-select-19-placeholder
{
  color: #CCC;
  font-weight: normal;
  letter-spacing: 0;
}
.inputSelect input[type="text"]{
  padding: 5px 0 !important;
}
.inputSelect > div:first-of-type > div:first-of-type{
  padding: 2px 8px 2px 0;
  font-weight: normal;
}


.layout-white .inputSelect > div:first-of-type:after {
  top: 42%;
  width: 14px;
  height: 14px;
  border-width: 3px;
}

.card .tableGrayThVertical .inputSelect > div:first-of-type{
  box-shadow: 0 0 0px 1px #CCC;
  padding: 1px 13px 0 20px;;
}

.card .tableGrayThVertical .inputSelect input[type="text"]{
  padding: 0 0 !important;
}


#container.contsLicenseeDetail .inputSelect,
#container.contsOriginatorDetail .inputSelect{
  margin: 20px 0;
}

#container.contsLicenseeDetail .inputSelect > div:first-of-type,
#container.contsOriginatorDetail .inputSelect > div:first-of-type{
  height: 42px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.12);
  padding-top: 0;
}
