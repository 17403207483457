
html,body,h1,h2,h3,h4,h5,h6,p,ul,ol,li,dl,dt,dd,img,object,table,caption,tbody,tfoot,thead,tr,th,td,address,iframe,code,pre,strong,em,
fieldset,form,input,select,optgroup,option,textarea,button,legend,label,span{margin:0;padding:0;border:0;background:none;outline:0;font-size:100%;font-style:normal;}
body{line-height:1.8;-webkit-text-size-adjust:none;}
button{line-height:1.8;}
ul,ol{list-style:none;}
img{vertical-align:bottom; -webkit-transform: translateZ(0); -webkit-backface-visibility: hidden;}
a,object{outline:0;}
table{border-collapse:collapse;border-spacing:0;}
input,select,button{vertical-align:middle;}
th,td{text-align:left;}

*, *:before, *:after {-webkit-box-sizing: border-box;box-sizing: border-box;}

.ml0 {margin-left:   0!important;}
.ml3 {margin-left: 3px!important;}
.ml5 {margin-left: 5px!important;}
.ml10{margin-left:10px!important;}
.ml15{margin-left:15px!important;}
.ml20{margin-left:20px!important;}
.ml25{margin-left:25px!important;}
.ml30{margin-left:30px!important;}
.ml35{margin-left:35px!important;}
.ml40{margin-left:40px!important;}
.ml50{margin-left:50px!important;}
.ml60{margin-left:60px!important;}

.mt0 {margin-top:   0!important;}
.mt3 {margin-top: 3px!important;}
.mt5 {margin-top: 5px!important;}
.mt7 {margin-top: 7px!important;}
.mt10{margin-top:10px!important;}
.mt15{margin-top:15px!important;}
.mt20{margin-top:20px!important;}
.mt25{margin-top:25px!important;}
.mt28{margin-top:28px!important;}
.mt30{margin-top:30px!important;}
.mt35{margin-top:35px!important;}
.mt40{margin-top:40px!important;}
.mt50{margin-top:50px!important;}
.mt60{margin-top:60px!important;}
.mt70{margin-top:70px!important;}
.mt80{margin-top:80px!important;}
.mt90{margin-top:90px!important;}
.mt100{margin-top:100px!important;}
.mt110{margin-top:110px!important;}
.mt120{margin-top:120px!important;}
.mt130{margin-top:130px!important;}
.mt140{margin-top:140px!important;}

.mr0 {margin-right:   0!important;}
.mr3 {margin-right: 3px!important;}
.mr5 {margin-right: 5px!important;}
.mr10{margin-right:10px!important;}
.mr15{margin-right:15px!important;}
.mr20{margin-right:20px!important;}
.mr25{margin-right:25px!important;}
.mr30{margin-right:30px!important;}
.mr35{margin-right:35px!important;}
.mr40{margin-right:40px!important;}
.mr50{margin-right:50px!important;}
.mr60{margin-right:60px!important;}

.mb0 {margin-bottom:   0!important;}
.mb3 {margin-bottom: 3px!important;}
.mb5 {margin-bottom: 5px!important;}
.mb10{margin-bottom:10px!important;}
.mb15{margin-bottom:15px!important;}
.mb20{margin-bottom:20px!important;}
.mb25{margin-bottom:25px!important;}
.mb30{margin-bottom:30px!important;}
.mb35{margin-bottom:35px!important;}
.mb40{margin-bottom:40px!important;}
.mb50{margin-bottom:50px!important;}
.mb60{margin-bottom:60px!important;}
.mb70{margin-bottom:70px!important;}
.mb80{margin-bottom:80px!important;}

/* common styles - padding */

.pl0 {padding-left:   0!important;}
.pl3 {padding-left: 3px!important;}
.pl5 {padding-left: 5px!important;}
.pl10{padding-left:10px!important;}
.pl15{padding-left:15px!important;}
.pl20{padding-left:20px!important;}
.pl25{padding-left:25px!important;}
.pl30{padding-left:30px!important;}
.pl35{padding-left:35px!important;}
.pl40{padding-left:40px!important;}
.pl50{padding-left:50px!important;}
.pl60{padding-left:60px!important;}

.pt0 {padding-top:   0!important;}
.pt3 {padding-top: 3px!important;}
.pt5 {padding-top: 5px!important;}
.pt7 {padding-top: 7px!important;}
.pt10{padding-top:10px!important;}
.pt15{padding-top:15px!important;}
.pt20{padding-top:20px!important;}
.pt25{padding-top:25px!important;}
.pt30{padding-top:30px!important;}
.pt35{padding-top:35px!important;}
.pt40{padding-top:40px!important;}
.pt50{padding-top:50px!important;}
.pt60{padding-top:60px!important;}
.pt70{padding-top:70px!important;}
.pt80{padding-top:80px!important;}
.pt90{padding-top:90px!important;}
.pt100{padding-top:100px!important;}
.pt130{padding-top:130px!important;}
.pt180{padding-top:180px!important;}
.pt215{padding-top:215px!important;}

.pr0 {padding-right:   0!important;}
.pr3 {padding-right: 3px!important;}
.pr5 {padding-right: 5px!important;}
.pr10{padding-right:10px!important;}
.pr15{padding-right:15px!important;}
.pr20{padding-right:20px!important;}
.pr25{padding-right:25px!important;}
.pr30{padding-right:30px!important;}
.pr35{padding-right:35px!important;}
.pr40{padding-right:40px!important;}
.pr50{padding-right:50px!important;}
.pr60{padding-right:60px!important;}

.pb0 {padding-bottom:   0!important;}
.pb3 {padding-bottom: 3px!important;}
.pb5 {padding-bottom: 5px!important;}
.pb10{padding-bottom:10px!important;}
.pb15{padding-bottom:15px!important;}
.pb20{padding-bottom:20px!important;}
.pb25{padding-bottom:25px!important;}
.pb30{padding-bottom:30px!important;}
.pb35{padding-bottom:35px!important;}
.pb40{padding-bottom:40px!important;}
.pb50{padding-bottom:50px!important;}
.pb60{padding-bottom:60px!important;}
.pb70{padding-bottom:70px!important;}
.pb80{padding-bottom:80px!important;}
.pb90{padding-bottom:90px!important;}
.pb100{padding-bottom:100px!important;}
.pb120{padding-bottom:120px!important;}
.pb170{padding-bottom:170px!important;}

/* common styles - float */
.fL{float:left!important;}
.fR{float:right!important;}
.fNN{float:none!important;}

/* common styles - text-align */
.tL{text-align:left!important;}
.tC{text-align:center!important;}
.tR{text-align:right!important;}

/* common styles - font-size */
.fs10{font-size:10px!important;}
.fs12{font-size:12px!important;}
.fs13{font-size:13px!important;}
.fs14{font-size:14px!important;}
.fs15{font-size:15px!important;}
.fs16{font-size:16px!important;}
.fs17{font-size:17px!important;}
.fs18{font-size:18px!important;}
.fs19{font-size:19px!important;}
.fs20{font-size:20px!important;}
.fs21{font-size:21px!important;}
.fs22{font-size:22px!important;}
.fs44{font-size:44px!important;}

/* common styles - clear float */
.clfix:after{content:'';display:block;clear:both;}
.clfix{zoom:1;}
.clear{clear:both;}

/* common width */
.w200px{width:200px!important;}
.w215px{width:215px!important;}
.w400px{width:400px!important;}
.w415px{width:415px!important;}
.w650px{width:650px!important;}
.w700px{width:700px!important;}
.w1080px{width:1080px!important;}

/* common other */
.fB{font-weight:700!important;}
.fM{font-weight:500!important;}
.fN{font-weight:300!important;}

.vT{vertical-align:top!important;}
.vM{vertical-align:middle!important;}
.vB{vertical-align:bottom!important;}

.dB{display:block;}
.dN{display:none;}
.dT{display:table;}

.mA{margin:0 auto;}

.tul{text-decoration:underline;}
a .blight{transition:opacity 0.2s linear;-webkit-transition:opacity 0.2s linear;-moz-transition:opacity 0.2s linear;}
a:hover .blight{opacity:0.7;filter:alpha(opacity=70);-moz-opacity:0.7;}




body.sb-show-main.sb-main-padded {
  color: #333;
  background: #F5F8F8;
  font-size: 100%;
  font-weight: bold;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  margin: 0 auto;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

html, body{
  color: #333;
  background: #F5F8F8;
  font-size: 100%;
  font-weight: bold;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  margin: 0 auto;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
a img.blight{transition:opacity 0.2s linear;-webkit-transition:opacity 0.2s linear;-moz-transition:opacity 0.2s linear;}
a:hover img.blight{opacity:0.7;filter:alpha(opacity=70);-moz-opacity:0.7;}
a{text-decoration:none;color:#64C8D2 !important;/*transition:opacity 0.2s linear;-webkit-transition:opacity 0.2s linear;-moz-transition:opacity 0.2s linear;*/}
a:hover{text-decoration:none;/*opacity:0.7;filter:alpha(opacity=70);-moz-opacity:0.7;*/}
button{font-family: 'Poppins', sans-serif;}
input[type="submit"]{font-family: 'Poppins', sans-serif;}



.loadingPanel{
  display: none;
  width:100vw;
  height: 100vh;
  position: fixed;
  top:0;
  left: 0;
  background-color: rgba(255,255,255,0.7);
  z-index: 100;
}

.loadingPanel::after{
  position: absolute;
  top:calc(50% - 44px);
  left:calc(50% - 44px);
  border-radius: 50%;
  width: 88px;
  height: 88px;
  border: 11px solid rgba(100,200,210, 0.5);
  border-top-color: #64C8D2;
  animation: spin 1s infinite linear;
  content:"";
}

#ContentRoute.loading .loadingPanel{
  display: block;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


img[alt=template] {
  z-index: -1;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}

.required_ico{
  color: #E65022;
}

textarea {
  border: 0;
  border-radius: 10px;
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  padding: 18px 30px;
  box-shadow: rgb(0 0 0 / 20%) 0px 0px 0px 1px inset;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: normal;
  line-height: 160%;
}

textarea::placeholder {
  color: #CCC;
}

input[type=checkbox] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 6px;
  box-sizing: border-box;
  border: 1px solid #777;
  position: relative;
  cursor: pointer;
  background-color: white;
}

input[type=checkbox]:checked::after {
  content: "";
  display: block;
  position: absolute;
  left: 3px;
  top: -1px;
  width: 16px;
  height: 8px;
  border-left: 2px solid #707070;
  border-bottom: 2px solid #707070;
  transform: rotate(-45deg);
}

input[type=checkbox][disabled] {
  background-color: lightgrey;
  cursor: default;
}

#container {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  font-size: 19px;
  text-align: left;
}

#container #containerMain{
  padding: 20px 30px 0;
  width:100%;
}

#container .contsDashboardHeader{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 3px 0 25px;
}

#container .contsDashboardHeader a{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 38px;
  border-radius: 19px;
  background: #64c8d2;
  color: white !important;
  text-decoration: none;
  font-size: 16px;
}

#container .contsDashboardHeader a::before{
  content:url("../parts/assets/menu_icon_message.svg");
  margin: 4px 7px 0 0;
}

#container .contsDashboardHeader a.contsDashboardHeaderMail::before{
  content:url("../parts/assets/menu_icon_mail.svg");
  margin: 2px 7px 0 0;
}

#container h1 span.badge,#container .contsDashboardHeader h2 span.badge {
  display: flex;
  width: 26px;
  height: 26px;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  background-color: #006181;
  color: white;
  border-radius: 13px;
  box-sizing: border-box;
  font-size: 14px;
  margin-left: 10px;
}

#container.contsMessageList #containerMain{
  padding-top: 30px;
}

#container.contsDashboard .card,
#container.contsMessageList .card{
  margin-bottom: 25px;
  padding: 20px 45px;
  min-width: fit-content;
}

#container.contsRequest .card{
  margin-bottom: 25px;
  min-width: fit-content;
}

#container.contsCreateAccount .card,
#container.contsAssetDetail .card{
  padding: 10px 40px 10px;
}
#container.contsAssetDetailList .card,
#container.contsAssetBookmark .card{
  padding: 30px 0 10px;
  margin-top: 10px;
}
#container.contsProfileSettings .card{
  padding: 10px 40px 0px;
}

#container.contsAssetEdit .card,
#container.contsProfileEdit .card,
#container.contsOrganizationEdit .card,
#container.contsLicenseeDetail .card,
#container.contsOriginatorDetail .card,
#container.contsMemberInvite .card,
#container.contsLicenseeProfile .card,
#container.contsOriginatorAdd .card,
#container.contsChangePassword .card{
  padding: 10px 40px 0px;
    margin-top: 10px;
    margin-bottom: 30px;
}
#container.contsMemberEntry .card{
  padding: 30px 40px 10px;
    margin-top: 10px;
}
#container.contsAssetAdd .card{
  padding: 10px 40px 10px;
    margin-top: 30px;
    width: 100%;
}
#container.contsAssetSearch .card{
  padding: 10px 0 40px;
  margin: 22px 0 0 0;
  width: 100%;
}

#container.contsProfileEdit .card p{
  font-size: 16px;
  font-weight: normal;
}
#container.contsRequest .card .listRequest{
  padding-top: 0;
}

#container.contsRequest .card .listRequest .listRequestHeader{
  padding-left: 0;
}

#container.contsMemberInvite p{
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  padding-top: 20px;
}
#container h2 {
  font-size: 22px;
  color:#006181;
  line-height: 1.4;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
}

#container h2.h2message::before{
  content:url("../parts/assets/icon_message.svg");
  margin: 5px 13px 0 0;
}

#container h2.h2mail::before{
  content:url("../parts/assets/icon_mail.svg");
  margin: 4px 8px 2px 0;
}

#container a {
  color: black;
  text-decoration: underline;
  cursor: pointer;
}

#container hr {
  border-top: 1px solid #AAA;
}

#container table.tableGray {
  border-collapse: collapse;
  border-spacing: 0;
  margin: 10px 30px 46px 0;
  width: 100%;
  font-size: 16px;
  font-weight: normal;
  line-height: 130%;
  display: table;
}

#container table.tableGray tr {
  width: 100%;
}

#container table.tableGray th {
  vertical-align: middle;
  font-weight: normal;
  font-weight: bold;
  font-size: 14px;
  white-space: nowrap;
}

#container table.tableGrayGemseki th:first-child{
  white-space: normal;
}

#container table.tableGrayThHorizontal th {
  background-color: #64C8D2;
  color: white;
  padding: 0 15px;
  text-align: left;
  min-width: 150px;
  line-height: 140%;
  height:58px
}

#container table.tableGrayThHorizontal th:first-child{
  border-radius: 12px 0 0 0;
}

#container table.tableGrayThHorizontal th:last-child{
  border-radius: 0 12px 0 0;
}

#container table.tableGrayThHorizontal tr:nth-child(even){
  background-color: #F5F5F5;
}

#container.contsAssetBookmark table.tableGrayThHorizontal th:first-child,
#container.contsAssetBookmark table.tableGrayThHorizontal td:first-child{
  width: 85px;
  text-align: center;
}

#container.contsAssetBookmark table.tableGrayThHorizontal td:first-child .buttonStarBox{
  width: auto;
  display: flex;
  justify-content: center;
  margin-right: 0;
  transform-origin: center center;
  transform: scale(0.8);
}

#container.contsAssetBookmark table.tableGrayThHorizontal td a{
  color:#64C8D2 !important;
}

#container table.tableGrayThVertical {
  margin-bottom: 20px;
}

#container table.tableGrayThVertical th {
  padding: 17px 10px 17px 0;
  width: 22%;
  text-align: left;
  color: #64C8D2;
  border-bottom: 1px solid #D0DCE0;
  vertical-align: top;
}

#container table.tableGrayThVertical td {
  width: 28%;
  padding: 17px 0;
  border-bottom: 1px solid #D0DCE0;
  vertical-align: top;
}

#container table.tableGrayThVertical tr:last-child th,
#container table.tableGrayThVertical tr:last-child td{
  border-bottom: none;
}

#container table.tableGrayThVertical td:last-child {
  width: auto;
}

#container table.tableGrayThVertical .checkBoxGroupBox {
  padding: 5px 0;
}

#container table.tableGrayThVertical .inputSelect {
  margin: 0 10px 0 0;
}

#container table.tableGrayThHorizontal td {
  font-size: 14px;
  padding: 10px 15px;
  text-align: left;
}

#container table.tableGrayThHorizontal td span{
  display: block;
  padding-bottom: 10px;
  line-height: 120%;
}

#container table.tableGrayThHorizontal td span:last-child{
  padding-bottom: 0px;
}

#container table.tableGray td p {
  padding: 0;
  margin: 0;
}

#container table.tableGray td .input1LineBox {
  margin: 0 10px;
}

#container.contsOrganizationEdit table.tableGray td .input1LineBox {
  margin: 0 10px 0 0;
}

#container table.tableGray td .checkBoxGroupBox {
  margin: 0 10px 5px;
}

#container.contsOrganizationEdit table.tableGray td .checkBoxGroupBox {
  margin: 0 10px 5px 0;
}

#container table.tableGray a.tableID {
  font-weight: bold;
}

#container table.tableGray .tableGraySearch {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#container table.tableGray.tableGrayThVertical textarea {
  width: 100%;
  margin-top: 7px;
  padding: 10px 20px;
  line-height: 150%;
  font-size: 16px;
  font-family: Arial;
}

#container table.tableGray.tableGrayThVertical .checkBoxGroup {
  padding: 0 0 10px 20px;
}


#container table.tableBookmark {
  margin-top: 30px;
  margin-bottom: 46px;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0px;
}

#container table.tableBookmark th {
  font-size: 14px;
  text-align: left;
  font-weight: normal;
  white-space: nowrap;
  padding: 0 20px 0 8px;
}

#container table.tableBookmark td {
  font-size: 16px;
  text-align: left;
  vertical-align: center;
  padding: 14px 8px 14px;
  border-bottom: 1px solid black;
}

#container table.tableBookmark td:first-child {
  width: 120px;
}

#container table.tableBookmark td:nth-child(2), #container table.tableBookmark td:nth-child(3) {
  /* width: 160px; */
}

#container table.tableBookmark td a.tableID {
  font-size: 18px;
  font-weight: bold;
}

#container table.tableBookmark .buttonStarBox {
  justify-content: flex-start;
  width: auto;
}

#container table.tableBookmark .buttonStarBox button {
  transform-origin: 0.5 0.5;
  transform: scale(0.78);
}


#container .buttonBoxLR {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

#container .buttonBoxL {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

#container .buttonBoxL.buttonBoxLBottom {
  align-items: flex-end;
}

#container .buttonBoxL.buttonBoxLBottom .buttonBoxLSelect label{
  color: #64C8D2;
  font-size: 14px;
  font-weight: bold;
  padding-bottom: 7px;
  display: block;
}

#container .buttonBoxCenter {
  display: flex;
  justify-content: center;
  padding-bottom: 30px;
  width: 100%;
}

#container .buttonBoxL .button,#container .buttonBoxCenter .button {
  margin-right: 18px;
  height: 38px;
}

#container .buttonBoxL button,#container .buttonBoxCenter button{
  height: 38px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

#container .buttonBoxL .buttonSize--174px button::before {
  content: url(../parts/assets/menu_icon_mail.svg);
  margin: 2px 7px 0 0;
}

#container .buttonBoxL .buttonSize--207px button::before {
  content: url(../parts/assets/menu_icon_message.svg);
  margin: 5px 9px 0 0;
}

#container.layout-white{
  font-family: 'Poppins', sans-serif;
}

#container.layout-white a{
  color: #4BA7C6 !important;
}
#container.layout-white main {
  width: 700px;
  display: flex;
  flex-direction: column;
  padding-bottom: 170px;
  margin: 0 auto;
}

#container.layout-white-full main {
  width: calc(100% - 300px);
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;
  padding-right: 40px;
  margin-left: 300px;
  flex-direction: column;
  padding-bottom: 80px;
}

#container.layout-white main h1, #container.layout-white-full main h1 {
  font-weight: 700;
  font-size: 52px;
  color: #4BA7C6;
  line-height: 1.4;
  margin: 60px 0 28px 0;
  letter-spacing: 1px;
  border: none;
  padding-left: 0;
}

#container.layout-white main h1::after, #container.layout-white-full main h1::after{
  display: none;
}



#container.layout-login {
  font-family: 'Noto Sans', 'Noto Sans JP', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}

#container.layout-login main {
  width: calc(100% - 274px);
  min-width: 800px;
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;
  padding: 0 0 80px;
  margin-left: 274px;
  flex-direction: column;
  position: relative;
}

#container.layout-login.contsAssetSearch main ,
#container.layout-login.contsAssetAdd main {
  padding: 0;
}

#container main h1 {
  position: relative;
  width:100%;
  height:87px;
  box-sizing: border-box;
  font-size: 22px;
  display: flex;
  white-space: nowrap;
  color:#006181;
  align-items: center;
  padding-left: 30px;
  border-bottom: 1px solid #D0DCE0;
}

#container main h1 .h1attn{
  font-size: 14px;
  font-weight: normal;
  margin: 5px 0 0 28px;
  display: block;
}

#container main h1::after{
  content: '';
  width: 100%;
  height: 1px;
  background-color: white;
  position: absolute;
  bottom: -2px;
  left: 0;
}

.contsDashboard h1::before{
  content:url("../parts/assets/icon_bell.svg");
  margin: 5px 9px 0 0;
}
.contsMessageList h1::before{
  content:url("../parts/assets/icon_mailopen.svg");
  margin: 2px 9px 0 0;
}
.contsRequest h1::before{
  content:url("../parts/assets/icon_message.svg");
  margin: 5px 9px 0 0;
}
.contsAssetSearch h1::before{
  content:url("../parts/assets/icon_search.svg");
  margin: 2px 9px 0 0;
}
.contsAssetBookmark h1::before{
  content:url("../parts/assets/icon_star.svg");
  margin: 4px 9px 0 0;
}
.contsProfileSettings h1::before{
  content:url("../parts/assets/icon_gear.svg");
  margin: 4px 9px 0 0;
}
.contsAssetAdd h1::before{
  content:url("../parts/assets/icon_docs.svg");
  margin: 2px 9px 0 0;
}


.cardBox {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding-top: 11px;
}

.csvDownload {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin:0 auto 25px;
  width: calc(100% - 80px);
}

.csvDownloadPages{
  color: #A0A0A0;
  font-size: 16px;
}
.csvDownload .button{
  width:184px;
  height: 42px;
  background: #64C8D2;
}

.csvDownload button{
  width:100%;
  height: 42px;
  font-size: 14px !important;
}

.csvDownload button::after{
  content:url("../parts/assets/icon_download.svg");
  margin: 6px 0 0 9px;
}



.memberInviteHeader {
  margin-top: 8px;
}

.memberInviteHeader h3 {
  font-weight: normal;
  font-size: 24px;
}

.memberOriginator {
  display: flex;
  align-items: center;
  padding: 30px 0 10px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.memberOriginator .memberOriginatorName{
  width: calc((100% - 27px)/2);
}
.memberOriginator .memberOriginatorName label{
  display: block;
  font-size: 14px;
  color: #64C8D2;
  font-weight: bold;
}
.memberOriginator .memberOriginatorName .buttonBoxL{
  padding-bottom: 0;
  padding-right: 0;
  justify-content: space-between !important;
}

.memberOriginator .memberOriginatorName .buttonBoxL .input1LineBox{
  width: calc((100% - 18px)/2);
  margin-bottom: 20px;
}
.memberOriginator .memberOriginatorName + .input1LineBox{
  width: calc((100% - 27px)/2);
  margin-bottom: 20px;
}
.memberOriginator .input1LineBox:last-child{
  width: 100%;
  margin-bottom: 20px;
}
.memberOriginator .input1LineBox:last-child .input1Line,
.memberOriginator .memberOriginatorName + .input1LineBox .input1Line,
.memberOriginator .memberOriginatorName .buttonBoxL .input1Line{
  width: 100% !important;
}
.memberOriginator label {
  white-space: nowrap;
  padding-bottom: 5px;
  padding-right: 23px;
}

.memberOriginator .buttonBoxL {
  padding-right: 23px;
}

.memberOriginator input {
  margin-right: 23px;
}

.memberOriginator + button {
  margin-bottom: 40px;
}
.memberOriginatorBtn{
  padding: 10px 0 40px;
}

.memberOriginatorBtn button{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  line-height: 100%;
  height: 44px;
}

.memberOriginatorBtn button::before{
  content:url("../parts/assets/menu_icon_person.svg");
  margin: 2px 9px 0 0;
}

.memberInviteSend {
  padding-top: 14px;
  padding-bottom: 36px;
  display: flex;
  align-items: flex-end;
}

.memberInviteSend .button {
  margin-left: 42px;
}

.memberInviteSend .button button {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  line-height: 100%;
  font-size: 16px;
}

.accountBody {
  width: 100%;
}

#container .accountBody h2 {
  font-size: 16px;
  margin-top: 15px;
  margin-bottom: 10px;
}

.accountBody .buttonBoxL {
  padding-bottom: 40px;
}

.accountBody .memberAddInput .buttonBoxL {
  padding-bottom: 0px;
}

.accountBody .memberAddInput .buttonBoxL input {
  margin-right: 23px;
}

.companyName {
  font-size: 36px;
  color:#64C8D2;
}

.contsMemberEntry p {
  font-size: 16px;
  font-weight: normal;
  padding: 0;
}

.contsMemberEntry .buttonBoxCenter{
  margin-top: 30px;
}

.contsMemberEntry .buttonBoxL{
  margin: 20px 0 30px;
}

.contsMemberEntry .buttonBoxL button::before{
  content:url("../parts/assets/menu_icon_gear.svg");
  margin: 2px 8px 0 0;
}

.contsChangePassword .inputBoxes {
  width: 700px;
  grid-template-columns: 1fr 460px;
  margin-bottom: 70px;
  height: 175px;
  display: grid;
  align-content: space-between;
}

.contsChangePassword .inputBoxes label {
  text-align: right;
  flex-grow: 1;
  padding-right: 52px;
  align-self: center;
}

.starNumber {
  position: absolute;
  right:30px;
  top:24px;
  display: flex;
  align-items: center;
}

.starNumber img{
  margin-right: 8px;
}

.contsAssetStatus{
  margin: 20px 0;
}

.contsAssetStatus .inputSelect > div:first-of-type{
  box-shadow: 2px 2px 8px 0px rgb(0 0 0 / 15%);
  height: 42px;
  width: 203px;
  padding: 1px 13px 0px 20px;
}

.contsAssetStatus .inputSelect > div:first-of-type > div:first-of-type{
  padding: 0px 8px;
}

.assetListBox {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.assetListCase {
  width: calc((100% - 40px) / 2);
}

.assetListCase h3 {
  font-size: 14px;
  font-weight: normal;
  padding-bottom: 8px;
}

#container .assetListCase table.tableGrayThHorizontal th{
  border-bottom: 1px solid #CCC;;
}
#container .assetListCase table.tableGrayThHorizontal th,
#container .assetListCase table.tableGrayThHorizontal td {
  text-align: left;
  vertical-align: middle;
  font-size: 16px;
  background: none;
  color: black;
}
#container .assetListCase table.tableGrayThHorizontal td {
  padding-top: 20px;
}
#container .assetListCase table.tableGrayThHorizontal tr:nth-child(even) {
  background: none;
}

#container .assetListCase table.tableGrayThHorizontal td a {
  font-weight: bold;
}

a.buttonNormal {
  border: 0;
  border-radius: 10px;
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  padding: 14px 30px 18px;
  font-size: 18px;
  box-sizing: border-box;
  text-decoration: none !important;
  box-shadow: rgb(0 0 0 / 40%) 0px 0px 0px 1px inset;
}

.contsDashboard .card:first-child {
  margin-top: 60px;
}

.contsDashboard .cardCenter,.contsMessageList .cardCenter,.contsRequest .cardCenter{
  width:100%;
  text-align: center;
  font-size: 22px;
  color: #A0A0A0;
  letter-spacing: 1px;
  padding: 6px 0;
  font-weight: normal;
}

.appErrorMessages {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  text-align: left;
}

.tabbox{
  width: 100%;
}

.tabcontent-area {background:#E6F0F2;padding: 5px 0 130px;box-shadow: 0 0 3px 2px rgba(0,0,0,0.07);}
.tabcontent {margin:0 30px;display: block;}
.contsAssetAddseeds .tabcontent {width:fit-content;}
.contsAssetAddtechnologies .tabcontent-area {min-width: 100%;}
.contsAssetAddtechnologies .tabcontent {width: auto; min-width:fit-content;}

.searchListbox{background:#fff;border-radius:20px;padding:27px 0 40px;margin:22px 0 0 0;width:100%;box-shadow: 2px 2px 8px 0px rgba(0,0,0,0.15);}
.searchListboxIn{width:100%;margin:0 auto; overflow-x: auto;}
.searchListboxIn table{margin:0 40px 15px !important;}
.searchListboxIn table td:nth-child(2){
  color: black;
}
.searchListboxIn table td a{
  color: #64C8D2 !important;
  white-space: nowrap;
}

.searchOptionBox {
  display: flex;
  align-items: flex-end;
  padding-bottom: 20px;
  position: relative;
}

.searchOptionBox .csvDownloadPages{
  position: absolute;
  bottom:46px; right:40px;
}

.searchOptionBox .buttonSize--170px{
  position: absolute;
  bottom:40px; right:40px;
}

.searchOptionBox .buttonSize--170px button{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  line-height: 100%;
  height: 44px;
}

.searchOptionBox .buttonSize--170px button::before{
  content:url("../parts/assets/menu_icon_person.svg");
  margin: 2px 9px 0 0;
}

.searchOptionBox .search{
  width: fit-content;
}

.searchOptionBox .search .input1LineBox{
  width: 320px;
  margin-right: 20px;
}
.searchOptionBox .search .input1Line {
  box-shadow: 0 0 0px 1px #ccc;
  padding: 10px 20px;
}

@keyframes messageBar {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.contsAssetEdit table.tableGray.tableGrayThVertical .checkBoxGroup,
.contsOriginatorAdd table.tableGray.tableGrayThVertical .checkBoxGroup,
.contsOrganizationEdit table.tableGray.tableGrayThVertical .checkBoxGroup,
.contsAssetAdd table.tableGray.tableGrayThVertical .checkBoxGroup{
  padding-left: 0px !important;
}

.contsAssetAdd table.tableGray.tableGrayThVertical .inputSelect{
  margin-left: 0 !important;
}

.contsAssetEdit table.tableGray.tableGrayThVertical tr:last-child th, .contsAssetEdit table.tableGray.tableGrayThVertical tr:last-child td,
.contsOriginatorAdd table.tableGray.tableGrayThVertical tr:last-child th, .contsOriginatorAdd table.tableGray.tableGrayThVertical tr:last-child td,
.contsAssetAdd table.tableGray.tableGrayThVertical tr:last-child th, .contsAssetAdd table.tableGray.tableGrayThVertical tr:last-child td {
  border-bottom: 1px solid #D0DCE0 !important;
}

.originatorAddMembers h2 {
  color: #64C8D2 !important;
  font-size: 20px !important;
}
.contsAssetEdit .ncdFiles + .buttonBoxCenter,
.contsAssetAdd .ncdFiles + .buttonBoxCenter,
.originatorAddMembers .buttonBoxCenter{
  border-top: 1px solid #D0DCE0 !important;
  padding-top: 30px;
  padding-bottom: 50px !important;
}

.contsCreateAccountConfirm p{
  line-height: 200%;
}

.contsCreateAccountConfirm h2{
  color: #4BA7C6 !important;
  font-size: 36px !important;
}

.contsCreateAccountConfirm .buttonBoxCenter{
    margin-top: 50px;
}

.contsCreateAccountConfirm .buttonBoxCenter .button,
.contsCreateAccountConfirm .buttonBoxCenter button{
  height: 52px !important;
}

#container .scroll-container{
  cursor: grab;
}
#container .scroll-container a{
  cursor: pointer;
}

#container .scroll-container table.tableGray{
  margin: 0px 40px 46px;
}

#container .scroll-container table.tableGray a{
  white-space: nowrap;
}

#container .searchListboxInFix{width:100%;margin:0 auto; padding: 0 0; overflow-x: auto;}
#container.contsAssetBookmark .scroll-container table,
#container.contsAssetDetailList .scroll-container table,
#container .searchListboxInFix .scroll-container table{
  width: calc(100% - 80px);
}

.tableBlockSS{width:150px;white-space:pre-wrap;}
.tableBlockS{width:200px;white-space:pre-wrap;}
.tableBlockM{width:max-content;max-width:300px;white-space:pre-wrap;}

#container .listOrder {
  position: relative;
  text-align: center;
  cursor: pointer;
  user-select: none;
}
#container .listOrder.selected {
  background: #41A5AF;
  color: white;
}
#container .listOrder::after,#container .listOrder.desc::after {
  content: url("../parts/assets/icon_desc.svg");
  transform: translate(8px, 1px);
  display: inline-block;
}
#container .listOrder.asc::after {
  content: url("../parts/assets/icon_asc.svg");
}
#container th.listOrder:first-child{
  padding-right: 35px;
}
#container th.listOrder:first-child::after,#container th.listOrder.desc:first-child::after {
  position: absolute;
  top: calc(50% + 1px);
  right: 10px;
  transform: translate(0, -50%);
}


a.backToTop {
  background: #4BA7C6;
  border-radius: 100px;
  text-align: center;
  box-shadow: 2px 2px 8px 0px rgb(0 0 0 / 15%);
  transition: all 0.8s;
  height: 44px;
  width: 160px;
  margin: 17px auto 0;
}
a.backToTop span{color:#fff;width:160px;display:block;line-height:50px;transition: all 0.8s; height: 44px; padding: 0; display: flex; justify-content: center; align-items: center; font-weight: normal;}
a.backToTop span:before {width:160px;}
a.backToTop:hover {box-shadow:0 0 0 0 rgba(0,0,0,0);}
a.backToTop:hover span {-moz-transform: translateY(0);-ms-transform: translateY(0);-webkit-transform: translateY(0);transform: translateY(0);box-shadow: 2px 2px 7px 2px rgba(0, 0, 0, 0.2) inset;border-radius:100px;}

.searchSortBox{
  border-bottom: 1px solid #D0DCE0;
  position: relative;
  margin-bottom: 12px;
}

.searchSortBox::after {
  content: '';
  width: 100%;
  height: 1px;
  background-color: white;
  position: absolute;
  bottom: -2px;
  left: 0;
}

#container .searchSortBox h2{
  font-size: 18px;
  letter-spacing: 0;
  padding: 10px 0 5px;
  cursor: pointer;
}

#container .searchSortBox h2::after {
  content: '';
  display: flex;
  width: 26px;
  height: 26px;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  background-color: #006181;
  color: white;
  border-radius: 13px;
  box-sizing: border-box;
  font-size: 12px;
  margin-left: 15px;
  transition-duration: 0.3s;
  background-repeat: no-repeat;
  background-position: center top 8px;
  background-image: url("../parts/assets/arrow_opcl.svg");
}

#container .searchSortBox.searchSortOpen--false h2::after {
  transform: rotate(180deg);
}

#container .searchSortBox .searchSortBody{
  height:287px;
  transition-duration: 0.3s;
}

@media (max-width: 1145px) {
  #container .searchSortBox .searchSortBody{
    height:343px;
  }
}

@media (max-width: 1127px) {
  #container .searchSortBox .searchSortBody{
    height:423px;
  }
}

#container .searchSortBox.searchSortOpen--false .searchSortBody{
  height: 10px;
  overflow-y: hidden;
}



.contsAssetBag h1::before {
  content: url("../parts/assets/icon_bag.svg");
  margin: 4px 9px 0 0;
}

.contsAssetBagMain{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 80px 30px;
}

#container.contsAssetBag h2{
  font-size: 30px;
  letter-spacing: 0;
  color: black;
  padding: 0 20px;
  margin: 0 auto 4px;
}

.contsAssetBagMain p{
  font-weight: normal;
  color: #333333;
  font-size: 16px;
  line-height: 150%;
}

.contsAssetBagMain .attn{
  color: #E65022;
  font-size: 14px;
}

.contsAssetBagList{
  display: flex;
  justify-content: space-between;
  padding-top: 40px;
  margin-bottom: 10px;
  width: 100%;
  position: relative;
}

.contsAssetBagList::after{
  content: '';
  width: 1px;
  height: 255px;
  background-color: #CCCCCC;
  position: absolute;
  left: 50%;
  bottom: 20px;
}

.contsAssetBagListL,
.contsAssetBagListR{
  width: calc(50% - 48px);
}

.contsAssetBagList h3{
  width: 100%;
  color: #64C8D2;
  font-size: 16px;
  text-align: center;
  padding-bottom: 5px;
}

.contsAssetBagList ul{
  width: 100%;
  border-top: 1px solid #CCCCCC;
  border-bottom: 1px solid #CCCCCC;
  padding: 0;
  margin: 0;
  height: 275px;
  overflow-y: auto;
}

.contsAssetBagList ul li{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 8px 0;
}

.contsAssetBagList ul li a{
  margin-left: 20px;
}

.contsAssetBagList ul li button{
  margin-right: 20px;
}

.contsAssetBagMain textarea{
  width: 80%;
}

.contsAssetBagMain .button{
  margin-top: 40px;
}

.contsAssetBagList ul li:nth-child(even){
  background-color: #F5F5F5;
}

.searchTipsBox{
  position: relative;
}

#container table.tableGrayThHorizontal th.bagTh{
  width: fit-content;
  min-width: fit-content;
  position: relative;
  padding-right: 32px;
}

#container table.tableGrayThHorizontal td.bagTd{
  text-align: center;
}

.bagTips{
  position: absolute;
  top:-63px;
  left: 69px;
  background: #006181;
  filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.12));
  font-size: 14px;
  font-weight: normal;
  color: white;
  line-height: 150%;
  border-radius: 6px;
  padding: 8px 20px;
  pointer-events: none;
  transition-duration: 0.3s;
  opacity: 0;
  transform: translateY(8px);
}

.bagTipsOpen--true{
  opacity: 0.9;
  transform: translateY(0px);
}

.bagTips::after{
  position: absolute;
  bottom: -20px;
  left: 30px;
  content:url("../parts/assets/arrow_tips.svg");
}

.bagTipsOn{
  position: absolute;
  top:19px;
  right: 6px;
}

.bagPlus{
  width: 24px;
  height: 24px;
  background-image: url("../parts/assets/icon_bag_plus.svg");
  cursor: pointer;
}

.bagPlus:disabled{
  background-image: url("../parts/assets/icon_bag_plus_non.svg");
}

.bagMinus{
  width: 24px;
  height: 24px;
  background-image: url("../parts/assets/icon_bag_minus.svg");
  cursor: pointer;
}

#container a.isValid--invalid{
  color: #E65022 !important;
}

#container a.isValid--invalid::after{
  content: url("../parts/assets/icon_invalid.svg");
  margin: 0 0 0 6px;
}

.gicon label{
  display: flex;
  align-items: flex-start;
}

.gicon label::after{
  content: url("../parts/assets/icon_g.svg");
  margin: 2px 0 0 4px;
  display: block;
}

#container.lang-ja{font-family: 'Poppins', "游ゴシック", YuGothic, "游ゴシック体", "ヒラギノ角ゴ Pro W3", "メイリオ", sans-serif !important; }
#container.lang-ja p,#container.lang-ja label{font-weight: 400;}
#container.lang-ja button{font-weight: 600; display: flex; justify-content: center; align-items: center; line-height: 100%; height: 48px;}


.tutorial{
  background: #FFF;
  border-radius: 16px;
  padding: 45px 25px 40px;
  position: relative;
  margin: 20px 0 48px;
  display: flex;
  box-sizing: border-box;
  box-shadow:5px 5px 10px 0px #e3e3e3 inset;
  align-items: center;
}

.tutorialClose{
  position: absolute;
  top:11px;
  right:13px;
  width:30px;
  height:30px;
  background-size: cover;
  background-image: url("../parts/assets/icon_close.svg");
  cursor: pointer;
}

.tutorialMain{
  padding: 0 20px;
}

.tutorialMain p{
  line-height: 130%;
  margin:8px 0 12px;
  color: #006181;
}

.tutorialLink {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 38px;
  border-radius: 19px;
  background: #64c8d2;
  color: white !important;
  text-decoration: none !important;
  font-size: 16px;
}

.tutorialLink::before {
  content: url("../parts/assets/menu_icon_search.svg");
  margin: 6px 9px 0 0;
}

.contsDashboard .tutorial{
  padding-bottom: 30px;
}
.contsDashboard .tutorialMain{
  padding: 0 20px 14px;
}

.contsAssetBookmark .tutorialMain{
  padding: 0 20px 0 4px;
}

.contsRequest .tutorial{
  padding: 25px 25px 25px;
}