.buttonH1Side{
  display: flex;
  flex-wrap: wrap;
  margin: 4px 0 7px;
  padding: 0 0 8px;
  justify-content: flex-end;
  border-bottom: 1px solid #D0DCE0;
  position: relative;
}

.buttonH1Side::after {
  content: '';
  width: 100%;
  height: 1px;
  background-color: white;
  position: absolute;
  bottom: -2px;
  left: 0;
}

.buttonH1Side li{
  list-style-type: none;
  padding: 0 0 8px 20px;
  box-sizing: border-box;
  width:calc((100% - 142px)/3);
  min-width:210px;
  position: relative;
}

.buttonH1Side li:first-child{
  padding-left: 0;
  width:142px;
  min-width:142px;
}

.buttonH1Side a{
  font-size: 16px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #A0A0A0;
  color: white !important;
  width:100%;
  height: 38px;
  font-size: 18px;
  border-radius: 20px;
  color:black;
  cursor: pointer;
  text-decoration: none !important;
  padding:0;
  box-sizing: border-box;
}

.boolAll--true .buttonH1Side--all{
  background: #64C8D2;
}

.boolAll--true .buttonH1Side--newRequest,.buttonH1Side--newRequest.buttonH1SideLinkActive--true{
  background: #D6A985;
}

.boolAll--true .buttonH1Side--confirming,.buttonH1Side--confirming.buttonH1SideLinkActive--true{
  background: #C8BE64;
}

.boolAll--true .buttonH1Side--declinedByOriginator,.buttonH1Side--declinedByOriginator.buttonH1SideLinkActive--true,
.boolAll--true .buttonH1Side--declinedByLicensee,.buttonH1Side--declinedByLicensee.buttonH1SideLinkActive--true{
  background: #BC6A6E;
}

.boolAll--true .buttonH1Side--approvedByOriginator,.buttonH1Side--approvedByOriginator.buttonH1SideLinkActive--true,
.boolAll--true .buttonH1Side--evaluating,.buttonH1Side--evaluating.buttonH1SideLinkActive--true{
  background: #93AD93;
}

.boolAll--true .buttonH1Side--pending,.buttonH1Side--pending.buttonH1SideLinkActive--true{
  background: #9EA7BB;
}


.H1SideTips{
  position: absolute;
  bottom:73px;
  left: calc(50% + 10px);
  background: white;
  filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.12));
  font-size: 14px;
  font-weight: normal;
  color: #006181;
  line-height: 150%;
  border-radius: 6px;
  padding: 8px 20px;
  pointer-events: none;
  transition-duration: 0.3s;
  transform: translateY(28px) translateX(-50%);
  text-align: center;
  opacity: 0;
  width:50%;
  min-width: 200px;
}

.TipsOpen--1 li:nth-child(2) .H1SideTips,
.TipsOpen--2 li:nth-child(3) .H1SideTips,
.TipsOpen--3 li:nth-child(4) .H1SideTips,
.TipsOpen--4 li:nth-child(5) .H1SideTips,
.TipsOpen--5 li:nth-child(6) .H1SideTips,
.TipsOpen--6 li:nth-child(7) .H1SideTips
{
  opacity: 1;
  transform: translateY(20px) translateX(-50%);
}

.H1SideTips::after{
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  content:url("../parts/assets/arrow_tips_w.svg");
}