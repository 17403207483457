.search {
  width: 100%;
  padding-top: 12px;
  padding-bottom: 18px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  padding: 20px 40px;
}

.searchLayout--gemseki-multi label {
  align-self: start;
}

.searchLayout--licensee-multi label {
  align-self: start;
}

.search label,.search .button{
  width:calc((100% - 54px)/4);
  min-width: 203px;
  margin-top: 10px;
}

.searchLayout--gemseki-multi label {
  width:calc((100% - 54px)/2);
  min-width: 203px;
  margin-top: 10px;
}

.searchLayout--gemseki-multi .input1LineBox{
  width:calc((100% - 86px)/ 1.3);
}

.searchLayout--gemseki-multi .button {
  width:calc((100% - 86px)/ 4);
  min-width: 203px;
  margin-top: 10px; 
}

.searchLayout--licensee-multi label {
  width:calc((100% - 54px)/2);
  min-width: 203px;
  margin-top: 10px;
}

.searchLayout--licensee-multi .input1LineBox{
  width:calc((100% - 86px)/ 1.3);
}

.searchLayout--licensee-multi .button {
  width:calc((100% - 86px)/ 4);
  min-width: 203px;
  margin-top: 10px; 
}

.search .inputSelect{
  margin-right: 0;
}

.search .inputSelect > div:first-of-type{
  box-shadow: 2px 2px 8px 0px rgb(0 0 0 / 15%);
  height: 42px;
  width: 100%;
  padding: 1px 13px 0px 20px;
}

.search .inputSelect > div:first-of-type > div:first-of-type{
  padding: 0px 8px;
}

.search .inputSelectMulti > div:first-of-type{
  padding: 4px 8px;
  height: 100%;
}

.searchLayout--licensee .input1LineBox{
  width:calc((100% - 54px)/4*3 + 36px);
}

.searchLayout--gemseki .input1LineBox{
  width:calc((100% - 54px)/4);
}

.search .input1LineBox input{
  margin: 4px 0 0;
  height: 42px;
  box-shadow: 2px 2px 8px 0px rgb(0 0 0 / 15%);
}

.searchLayout--messageList{
  padding: 0 0 20px;
  justify-content: flex-start;
}

.searchLayout--messageList .input1LineBox{
  display: none;
}

.searchLayout--messageList .inputSelectLabel{
  margin-right: 18px;
}

.searchLayout--messageList label{
  margin-top: 0px;
}

.searchLayout--request{
  padding: 0 0 30px;
}

.searchLayout--request .input1LineBox{
  width:calc((100% - 54px)/4*3 + 36px);
}

.searchOptionBox .searchLayout--valid{
  width:calc(100% - 220px);
  position: relative;
}

.searchOptionBox .searchLayout--valid::after{
  content: '';
  width:1px;
  height: calc(100% - 75px);
  position: absolute;
  background-color: #D6D6D6;
  bottom: 18px;
  right: 14px;
}

.searchLayout--valid .inputSelectLabel{
  margin-right: 18px;
}

.searchOptionBox .searchLayout--valid .input1LineBox{
  width:calc(100% - 520px);
  min-width: 300px;
}

.searchOptionBox .searchLayout--valid .button{
  margin-top: 38px;
}