.popup {
  position: fixed;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup.hide {
  display: none;
}

.popupBase {
  position: fixed;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(44, 44, 44, 0.7);
  z-index: 10;
}

.popupFrame{
  position: relative;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 11;
  min-width: 800px;
  box-sizing: border-box;
  padding: 36px 65px;
  border-radius: 20px;
  max-width:800px;
}

.popupFrame h2{
  margin: 5px 0 15px;
  color: black !important;
  font-size: 32px !important;
}

.popupFrame h2.popupTitle--center{
  text-align: center;
}

.popupFrame h4{
  text-align: center;
  padding-bottom: 10px;
}

.popupFrame p{
  text-align: center;
  line-height: 140%;
  margin-top: 10px;
  font-size: 16px;
  font-weight: normal;
  margin-left: -40px;
  margin-right: -40px;
}

.popupFrame a.buttonSetting{
  width: 233px;
  height: 46px;
  border-radius: 23px;
  background-color: #D3D3D3;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0 20px;
  cursor: pointer;
  text-decoration: none !important;
}

.popupFrame a.buttonSetting::after{
  content:'→';
  margin-left: 16px;
}

.popupFrame textarea{
  width: 600px;
  box-shadow: rgb(0 0 0 / 10%) 0px 0px 5px 1px ;
}

.popupButton{
  width: 286px;
  margin-top: 27px;
  display: flex;
  justify-content: space-between;
}

.popupButton .button,.popupButton button{
  width: 133px;
  height: 38px;
  padding: 0;
  margin: 0;
  line-height: 100%;
  font-size: 16px;
}

.popupButton .button:first-child{
  background: #D6D6D6;
}

.popupButton .button:first-child button{
  color: black !important;
}

.popupButton .button:last-child{
  background: #64C8D2;
}

.popupButton .button:first-child:last-child{
  margin-left: 76px;
}

.popupButton .button:first-child:last-child button{
  color: white !important;
}

.popupHide{
  display: none;
}

.popupStatus,.toAccountSettings{
  margin-top: 3px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
  width: 248px;
  height: 38px;
  border-radius: 19px;
  background: #ededed;
  color: white !important;
  text-decoration: none !important;
}

.popupStatus.newRequest{
  background: #D6A985;
}
.popupStatus.confirming{
  background: #C8BE64;
}
.popupStatus.declinedByOriginator,
.popupStatus.declinedByLicensee{
  background: #BC6A6E;
}
.popupStatus.approvedByOriginator,
.popupStatus.evaluating{
  background: #93AD93;
}
.popupStatus.pending{
  background: #9EA7BB;
}
.toAccountSettings{
  background: #64C8D2;
}

.popupDecline{
  display: flex;
  justify-content: center;
  flex-direction: column;
}
