.button{
  background:#64C8D2;
  width:160px;border-radius:100px;text-align:center;transition: all 0.8s;
}

.layout-white .button{
  background:#4BA7C6;
  box-shadow: 2px 2px 8px 0px rgba(0,0,0,0.15);
}

.button:hover {box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);}

.button button{
  color:#fff;
  display:block;line-height:44px;transition: all 0.8s;font-weight:500;text-align:center;
  cursor: pointer;width:100%;font-size:18px;display:block;
}

#container .button a{
  color:#fff !important; font-size:16px; text-decoration: none; font-weight: 500;font-family: 'Poppins', sans-serif;
}

.button:hover button{-moz-transform: translateY(0);-ms-transform: translateY(0);-webkit-transform: translateY(0);transform: translateY(0);box-shadow: 2px 2px 7px 2px rgba(0, 0, 0, 0.1) inset;border-radius:100px;}

.button--white{background:#fff !important;}
.button--white button{color:#4BA7C5;}

.button--gray,.layout-white .button.button--gray{background:#D6D6D6;}
.button--gray button{color:black;}

.button button:disabled{
  border-radius: 19px;
  background-color: rgba(255, 255, 255, 0.8);
}

.layout-white .button button:disabled{
  border-radius: 22px;
}
