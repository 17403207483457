.card {
  background: #FFF;
  border-radius: 27px;
  padding: 33px 25px 35px;
  position: relative;
  margin-bottom: 52px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  box-shadow: 1px 1px 8px #DDD;
}

.card.cardSize--full{
  width:100%;
}

.card.cardSize--half{
  width:calc((100% - 30px) / 2);
}

.card.cardSize--dashboard{
  padding: 13px 40px;
  width: 100%;
  margin-bottom: 60px;
}

.card.cardSize--dashboard:first-child{
  margin-top: 60px;
}

.cardNew--Message .cardNewBody{
  padding: 20px 0;
}

.card.cardSize--messageList{
  padding: 3px 40px 5px 50px;
  margin-top: 26px;
  margin-bottom: 18px;
}

.card.cardSize--request{
  padding: 2px 40px 0 50px;
  width: 100%;
}

.card.cardUnread--true{
  background: #F8F8F8;
}

.card.cardUnread--true::after{
  position: absolute;
  top:49px;
  left: 15px;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  background: #767676;
  content:"";
}

.card .cardBudge{
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: #707070;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  top: -10px;
  right: -10px;
  padding-bottom: 3px;
  box-sizing: border-box;
}



.cardRequest{
  margin-top: 12px;
  display: flex;
  flex-direction: column;
}

.cardRequest .cardRequestHeader{
  display: flex;
  width: 100%;
  border-bottom: 1px solid #D3D3D3;
  padding-bottom: 17px;
}

.cardRequest .cardRequestHeader dl{
  display: flex;
  align-items: center;
  width: 50%;
}

.cardRequest .cardRequestHeader dl dt{
  font-size: 14px;
  padding: 0 33px;
}

.cardRequest .cardRequestHeader dl:first-child dd a{
  font-size: 18px;
  font-weight: bold;
  text-decoration: underline;
  color: black;
  cursor: pointer;
}

.cardRequest .cardRequestHeader dl:last-child dd{
  font-size: 16px;
}

.cardRequest .cardRequestNote{
  padding: 25px 8px 23px;
  border-bottom: 1px solid #D3D3D3;
}

.cardRequest .cardRequestNote dt{
  font-size: 14px;
  padding-bottom: 6px;
}

.cardRequest .cardRequestNote dd{
  line-height: 160%;
}

.cardRequestFooter{
  margin-top: 21px;
  display: flex;
  justify-content: space-between;
}

.cardRequestFooter .cardRequestFooterLeft{
  margin-right: 27px;
}

.cardRequestFooter .cardRequestFooterLeft p{
 white-space: pre-wrap;
}

.cardRequestFooter .cardRequestFooterRight{
  display: flex;
}

.cardRequestFooter .cardRequestFooterRight .button{
  margin-left: 20px;
}

.cardRequestFooter .cardRequestFooterRight .button button:disabled {
  background-color: #CCC;
}

.cardRequestFooter.cardRequestFooter--Declined{
  /* justify-content: space-between; */
}

.cardRequestFooter.cardRequestFooter--Declined .cardRequestFooterLeft{
  display: flex;
  align-items: center;
}

.cardRequestFooter.cardRequestFooter--Declined .cardRequestFooterLeft p{
  margin:0 0 0 27px;
  font-size: 16px;
  padding: 0;
}

.cardNew ul{
  list-style-type: none;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 100%;
}

.cardNew ul li{
  border-top: 1px solid #D0DCE0;
}

.cardNew ul li:first-child{
  border-top: none;
}

.cardNewHeader{
  display: flex;
  align-items: center;
}

.cardNewHeader h3{
  font-weight: normal;
  width: 265px;
}

.cardNewHeader a{
  background-color: #D3D3D3;
  padding: 11px 13px 16px;
  width: 170px;
  border: 0;
  cursor: pointer;
    display: inline-block;
    line-height: 1;
    font-size: 18px;
    box-sizing: border-box;
    border-radius: 10em;
    text-decoration: none !important;
}

.listRequest,.listMessage{
  width: 100%;
  display: block;
  position: relative;
}

.listRequest .unread,.listMessage .unread{
  width: 18px;
  height: 18px;
  background: #ffdc5a;
  border-radius: 9px;
  position: absolute;
  top:50%;
  left:-26px;
  transform: translateY(-50%);
}

.listMessage {
  padding: 23px 0;
  position: relative;
}

.listMessage ,.listMessage * {
  cursor: pointer;
}

.listMessage:after {
  content: "";
  position: absolute;
  right: 0;
  top: 52%;
  width: 14px;
  height: 14px;
  border-bottom: 3px solid #64C8D2;
  border-left: 3px solid #64C8D2;
  transform: translateY(-50%) rotate(-135deg);
  pointer-events: none;
}

.listMessage:first-child {
  padding-top: 0;
}

.listMessage:last-child {
  padding-bottom: 0;
}

.listMessage a{
  color: black;
  cursor: pointer;
  text-decoration: none !important;
}

.listMessage .listRequestHeader a{
  text-decoration: underline !important;
}

.listMessage .message{
  padding-right: 20px;
  display: block;
}

.listMessageId{
  position: relative;
  font-size: 18px;
  font-weight: bold;
  color: #64C8D2 !important;
}

.listMessageBody{
  margin-top: 20px;
}

.listMessageBody label{
  display: block;
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 11px;
  color: #333333 !important;
}

.listMessageBody label span{
  padding-left: 20px;
  font-weight: normal;
  font-size: 14px;
}

.listMessageBody p{
  padding: 0;
  margin: 0;
  line-height: 160%;
  font-weight: normal;
  color: #333333 !important;
  font-size: 14px;
}



.listRequest{
  padding: 40px 0 20px;
}

.listRequest:first-child{
  padding: 20px 0 20px;
}

.listRequestHeader{
  display: flex;
  align-items: center;
}

.listMessageHeader--true .listRequestHeader{
  margin-top: 0;
  padding-left: 0;
}

.listRequestHeader li{
  display: flex;
  align-items: center;
  vertical-align: middle;
  font-size: 14px;
  font-weight: normal;
  padding-right: 25px;
  padding-left: 25px;
  border-top: none !important;
  margin-bottom: 0;
  line-height: 180%;
  position: relative;
}

.listRequestHeader li::before{
  background-color: #D6D6D6;
  height: 30px;
  width: 1px;
  content: '';
  position: absolute;
  left: 0;
  top:50%;
  transform: translateY(-50%);
}

.listRequestHeader li a{
  font-size: 22px;
  font-weight: bold;
  color: #64C8D2 !important;
}

.listRequestHeader li span{
  color: #999;
  padding-right: 20px;
}

.listRequestHeader li.listRequestDate{
  display: flex;
  align-items: center;
  font-size: 18px;
  position: relative;
  padding-left: 0; 
}

.listMessageHeader--true .listRequestHeader li.listRequestDate{
  color: #333;
}

.listRequestHeader li.listRequestDate::before{
  display: none;
}

.listRequestBody{
  padding-top: 30px;
  font-size: 16px;
  padding-bottom: 10px;
}

.listRequestBody label{
  display: block;
  font-size: 14px;
  padding-bottom: 5px;
  font-weight: normal;
  color: #999;
}

.listRequestMain{
  white-space: pre-wrap;
  font-size: 16px;
  font-weight: normal;
}


.cardRequestFooter .inputSelect > div:first-of-type > div:first-of-type,
.buttonBoxLSelect .inputSelect > div:first-of-type > div:first-of-type{
  padding: 0 8px;
}

.cardRequestFooter .inputSelect > div:first-of-type > div:first-of-type > div,
.buttonBoxLSelect .inputSelect > div:first-of-type > div:first-of-type > div{
  color: white;
  font-weight: bold;
  margin-top: -5px;
  margin-bottom: -5px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.cardRequestFooter .inputSelect > div:first-of-type:after,
.buttonBoxLSelect .inputSelect > div:first-of-type:after {
  border-color: white;
  border-width: 2px;
  width: 10px;
  height: 10px;
}

.cardRequestFooter .inputSelect > div:first-of-type,
.buttonBoxLSelect .inputSelect > div:first-of-type{
    padding: 1px 13px 1px 20px;
}

.cardRequestFooter .button{
  height:38px;
  background: #64c8d2;
}

.cardRequestFooter .button button{
  line-height: 100%;
  height:38px;
  font-size: 16px;
}

.cardRequestFooter .button button::before {
  content:url("./assets/menu_icon_mail.svg");
  margin: 4px 7px 0 0;
}

.cardRequestFooter .button.button--request button::before {
  content:url("./assets/menu_icon_message.svg");
}

.cardRequestReason{
  font-size: 16px;
  font-weight: normal;
}

.cardRequestFooter--newRequest .inputSelect > div:first-of-type{
  background-color: #D6A985;
}
.cardRequestFooter--confirming .inputSelect > div:first-of-type{
  background-color: #C8BE64;
}
.cardRequestFooter--declinedByOriginator .inputSelect > div:first-of-type,
.cardRequestFooter--declinedByLicensee .inputSelect > div:first-of-type{
  background-color: #BC6A6E;
}
.cardRequestFooter--approvedByOriginator .inputSelect > div:first-of-type,
.cardRequestFooter--evaluating .inputSelect > div:first-of-type{
  background-color: #93AD93;
}
.cardRequestFooter--pending .inputSelect > div:first-of-type{
  background-color: #9EA7BB;
}
