.contsChangePassword .inputBoxes {
  width: 800px;
  grid-template-columns: 1fr 460px;
  margin: 30px 0 50px;
  height: 175px;
  display: grid;
  align-content: space-between;
}

.contsChangePassword .inputBoxes .inputBoxLabel {
  text-align: left;
  flex-grow: 1;
  padding-right: 80px;
  align-self: flex-start;
  font-size: 14px;
  color: #64C8D2;
  padding-top: 8px;
}

.contsChangePassword .inputBoxes .inputBoxArea select:first-child {
  margin-right: 25px;
}

.contsChangePassword .inputBoxes .inputBoxRight{
}

.contsChangePassword .inputBoxes .inputBoxRight .input1Line {
  box-shadow: 0 0 0px 1px #ccc;
  padding: 10px 20px;
}

.contsChangePassword .inputBoxes .inputBoxRight .inputBoxAttn{
  font-size: 14px;
  font-weight: normal;
  padding: 2px 0 0 10px;
}