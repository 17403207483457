.MessageDetailBody{
  padding: 116px 30px 20px;
}

.chatTalk {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 30px;
}

.chatTalk--l {
  align-items: flex-start;
}

.chatTalk--r {
  align-items: flex-end;
}

.chatTalkHead{
  display: flex;
  font-size: 14px;
  font-weight: normal;
  padding-bottom: 15px;
  align-items: center;
}
.chatTalk--r .chatTalkHead{
  justify-content: flex-end;
}

.chatTalkHead .chatTalkContributor{
  font-weight: bold;
  font-size: 16px;
}

.chatTalkMain{
  font-weight: normal;
  font-size: 16px;
  line-height: 180%;
}

.chatTalkMain a{
  font-weight: bold;
}

.chatTalkContributor{
  box-sizing: border-box;
  padding-right: 16px;
  min-width: 120PX;
}

.chatTalkPostDate{
  padding-right: 8px;
}

.chatTalkBody{
  width: 85%;
  box-sizing: border-box;
  border-radius: 27px;
  padding: 23px 35px;
}

.chatTalkBody a{
  text-decoration: underline;
}

.chatTalk--l .chatTalkBody{
  background: white;
  color: black;
}

.chatTalk--l .chatTalkBody a{
  color: black;
}

.chatTalk--r .chatTalkBody{
  background: #41A5AF;
  color: white;
}

.chatTalk--r .chatTalkBody a{
  color: white !important;
}
