aside.asideMain {
  position: fixed;
  top: 0;
  left: 0;
  width: 274px;
  height: 100vh;
  overflow-y: auto;
  background: #64C8D2;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Noto Sans', 'Noto Sans JP', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  z-index: 10;
}

@keyframes closeMenu {
  0% { width: 274px }
  100% { width: calc(100dvw - 100% + 74px); }
}

@keyframes openMenu {
  0% { width: calc(100dvw - 100% + 74px) }
  100% { width: 274px }
}

.openSideMenu {
  animation-duration: .5s;
  animation-name: openMenu;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.closeSideMenu {
  display: block !important;
  overflow-x: hidden !important;
  animation-duration: .5s;
  animation-name: closeMenu;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.closeMain {
  width: 100% !important;
  margin-left: 78px !important;
}

.closeArrow {
  width: 48px !important;
  margin: 26px 8px !important;
  height: 135px;
}

.closeSideMenuChild,
.closeSideMenuChild > *,
.closeSideMenuChild.asideList--true::before,
.closeSideMenuChild.asideList--true::after {
  width: 42px !important;
  margin-left: 8px !important;
  padding: 0 !important;
}

.visibilityNone {
  visibility: hidden;
}

aside a {
  text-decoration: none !important;
}

aside.asideWhite {
  position: fixed;
  top: 0;
  left: 0;
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.asideMainHeader{
  margin: 26px auto 22px;
  width: 226px;
  text-align: left;
}

.asideMainHeader a{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  width:150px;
  height:40px;
  border-radius: 20px;
  float: left;
}

.closeArrow a {
  width: 40px !important;
  margin: 13px 11px !important;
}

.asideMainHeader a img{
  width:93px;
}

.closeArrow a img {
  width: 18px !important;
}

aside.asideWhite .asideMainHeader{
  padding: 64px 0 8px;
}

.asideMainHeader h2{
  color: white;
  font-size:20px;
  letter-spacing: 1px;
  line-height: 145%;
  padding: 20px 0 3px;
}

.asideMainHeader h3{
  color: white;
  font-size:14px;
  margin: 0;
  padding: 0;
  line-height: 100%;
}

aside.asideWhite .asideMainHeader h2 a{
  text-align: center;
  margin: 0 auto;
  display: block;
}

aside.asideMain ul{
  width: 240px;
  border-top:1px solid #41A5AF;
  padding: 27px 0 10px;
  margin: 0;
  position: relative;
}

.asideMainFooter{
  width: 240px;
  border-top:1px solid #41A5AF;
  display: flex;
  justify-content: center;
  padding: 35px 0 60px;
  position: relative;
}

.asideMainFooter::before, aside.asideMain ul::before{
  content: '';
  width: 240px;
  height: 1px;
  background-color:#A0E6F0;
  position: absolute;
  top: 0;
  left: 0;
}

.asideLiProfile .asideList a,
.asideLiProfile .asideList--true::before,
.asideLiProfile .asideList--true::after,
.asideLiProfile .button,
.asideLiProfile .button button,
.asideLiAdmin .asideList a,
.asideLiAdmin .asideList--true::before,
.asideLiAdmin .asideList--true::after,
.asideLiAdmin .button,
.asideLiAdmin .button button{
  height: 34px;
  width: 100%;
  transition: all 0s;
  box-shadow:none;
}

.asideLiAdmin .asideList a{
  padding-left: 38px;
}

.asideLiProfile .button button{
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 12px 0 38px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 22px;
  box-shadow: none;
  box-sizing: border-box;
  color: white !important;
  background: #64C8D2;
}
.asideLiProfile .button button:hover{
  background-color:#49C0CE;
}

aside.asideMain .asideLiProfile,
aside.asideMain .asideLiAdmin{
  padding: 21px 0 19px;
}

aside.asideMain .asideLiProfile li,
aside.asideMain .asideLiAdmin li{
  height: 37px;
}

.asideLiLicensee li:nth-child(1) a::before,
.asideLiGemseki li:nth-child(1) a::before{
    content:url("./assets/menu_icon_bell.svg");
  position: absolute;
  left: 12px;
  top: 10px;
}

.asideLiLicensee li:nth-child(2) a::before,
.asideLiGemseki li:nth-child(2) a::before{
  content:url("./assets/menu_icon_mail.svg");
  position: absolute;
  left: 12px;
  top: 8px;
}

.asideLiLicensee li:nth-child(3) a::before,
.asideLiGemseki li:nth-child(5) a::before{
  content:url("./assets/menu_icon_search.svg");
  position: absolute;
  left: 12px;
  top: 10px;
}

.asideLiLicensee li:nth-child(4) a::before{
  content:url("./assets/menu_icon_bag.svg");
  position: absolute;
  left: 12px;
  top: 10px;
}

.asideLiLicensee li:nth-child(5) a::before{
  content:url("./assets/menu_icon_star.svg");
  position: absolute;
  left: 12px;
  top: 10px;
}

.asideLiLicensee li:nth-child(6) a::before,
.asideLiGemseki li:nth-child(3) a::before{
  content:url("./assets/menu_icon_ncd.svg");
  position: absolute;
  left: 9px;
  top: 9px;
}

.asideLiLicensee li:nth-child(7) a::before,
.asideLiGemseki li:nth-child(4) a::before{
  content:url("./assets/menu_icon_cda.svg");
  position: absolute;
  left: 9px;
  top: 9px;
}

.asideLiProfile li:nth-child(1) a::before{
  content:url("./assets/menu_icon_gear.svg");
  position: absolute;
  left: 10px;
  top: 7px;
}

.asideLiProfile button::before{
  content:url("./assets/menu_icon_logout.svg");
  position: absolute;
  left: 8px;
  top: -2px;
}

.asideLiGemseki li:nth-child(6) a::before{
  content:url("./assets/menu_icon_docs.svg");
  position: absolute;
  left: 13px;
  top: 9px;
}

.asideLiGemseki li:nth-child(7) a::before{
  content:url("./assets/menu_icon_licensee.svg");
  position: absolute;
  left: 13px;
  top: 9px;
}

.asideLiGemseki li:nth-child(8) a::before{
  content:url("./assets/menu_icon_originator.svg");
  position: absolute;
  left: 13px;
  top: 9px;
}

.asideLiAdmin li:nth-child(1) a::before{
  content:url("./assets/menu_icon_wordpress.svg");
  position: absolute;
  left: 9px;
  top: 6px;
}

.asideLiAdmin li:nth-child(2) a::before{
  content:url("./assets/menu_icon_salesforce.svg");
  position: absolute;
  left: 9px;
  top: 6px;
}

.asideLiAdmin li:nth-child(3) a::before{
  content:url("./assets/menu_icon_ga.svg");
  position: absolute;
  left: 9px;
  top: 6px;
}

.arrow {
  position: relative;
  /* display: inline-block; */
  width: 36px !important;
  height: 36px;
  cursor: pointer;
  float: right;
}

.arrow .inner,
.arrow .inner::before,
.arrow .inner::after {
  position: absolute;
  width: 20px;
  height: 2px;
  background: #fff;
  transition-duration: .15s;
  transition-property: width, transform;
  transition-timing-function: ease-in-out;
  content: '';
}

.arrow .inner {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

.arrow .inner::before { top:-8px; }
.arrow .inner::after  { top: 8px; }

.arrow:hover .inner::before,
.arrow:hover .inner::after {
  width: 16px;
  will-change: width, transform;
}

.arrow.left:hover  .inner::before { transform: translateY( 13px) translateX(-4px) rotate( 45deg); }
.arrow.left:hover  .inner::after  { transform: translateY(-13px) translateX(-4px) rotate(-45deg); }
.arrow.right:hover .inner::before { transform: translateY( 13px) translateX( 7px) rotate(-45deg); }
.arrow.right:hover .inner::after  { transform: translateY(-13px) translateX( 7px) rotate( 45deg); }
