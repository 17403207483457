.buttonStarBox{
  display: flex;
  width:100%;
  justify-content: flex-end;
  margin-right: 30px;
}

.buttonStar {
  border: 0;
  cursor: pointer;
  width:26px;
  height:26px;
  background-image: url(assets/icon-star-selected.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  background-color: transparent;
  margin: 0;
  padding: 0;
}

.buttonStarSelected--true {
  background-image: url(assets/icon-star.svg);
}
