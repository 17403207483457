.memberList{
  padding: 20px 0;
  width: 100%;
}

.memberListHeader{
  display: flex;
  padding-bottom: 10px;
}

.memberListHeader h3{
  font-weight: normal;
  font-size: 24px;
}

.memberList ul{
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.memberList ul li{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  padding: 0 0 5px;
  font-size: 16px;
}

.memberList ul li input[type=checkbox]{
  margin-right: 21px;
}

.memberList ul li .memberListName{
  font-weight: bold;
  width: 255px;
  box-sizing: border-box;
  padding-right: 20px;
}

.memberList ul li .memberListJobTitle{
  font-weight: normal;
  width: 150px;
  box-sizing: border-box;
  padding-right: 20px;
}

.memberList ul li a{
  font-weight: normal;
}

.MemberAddUl{
  width: 100%;
  padding: 0;
  margin: 20px 0 50px;
  list-style-type: none;
}

.memberAdd{
  width: 100%;
  justify-content: space-between;
}
.memberAdd, .memberAddInput, .memberAddInput label{
  display: flex;
}

.memberAddInput{
  width:calc(100% - 60px);
}
.memberAddInput label{
  flex-direction: column;
}

.memberAddInput label span{
  font-size: 14px;
  color: #64C8D2;
  font-weight: bold;
}

.memberAddInput{
  flex-wrap: wrap;
  padding: 5px 0;
}

.memberAddInput > label{
  padding-right: 30px;
}

.memberAddInput > label span{
  padding: 6px 0px 3px;
  line-height: 100%;
}
.memberAddInput > label input{
  margin: 3px 0;
}

.memberAddInput .input1Line{
  width:276px!important;
}

.memberAddInput .buttonBoxL{
  width: fit-content!important;
}

.memberAddInput .buttonBoxL .input1LineBox .input1Line{
  margin-right: 10px;
  width:133px!important;
}

.memberAddInput .buttonBoxL .input1LineBox:last-child .input1Line{
  margin-right: 0;
}
.memberAddInput > .input1LineBox{
  margin-right: 30px;
}
.memberAddInput > .input1LineBox:last-child{
  margin-right: 0px;
}
.memberAddButton{
  display: flex;
  width: 50px;
  align-items: center;
}

.memberAddButton .button{
  width: 26px;
  height: 26px;
  padding: 1px 0 0;
  margin: 22px 12px 0;
}

.memberAddButton button{
  width: 26px;
  height: 26px;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 100%;
}

.accountBody h2{
  display: flex;
  align-items: center;
}

.accountBody h2 .button{
  margin-left: 20px;
  width: 26px;
  height: 26px;
  padding: 1px 0 0;
}

.accountBody h2 button{
  width: 26px;
  height: 26px;
  margin: 0;
  padding: 0;
  display: flex;
  line-height: 100%;
  justify-content: center;
  align-items: center;
}