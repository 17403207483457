footer{font-weight:400; background:#fff;padding:40px 0 20px 0;position:relative; text-align: left;font-family: 'Poppins', sans-serif;}
footer.lang-ja{font-family: 'Poppins', "游ゴシック", YuGothic, "游ゴシック体", "ヒラギノ角ゴ Pro W3", "メイリオ", sans-serif !important; }
footer ul{padding: 0; margin: 0;}
footer li{margin: 0;}
footer .f_in{width:1080px;margin:0 auto;}

footer .f_in_in1 p{font-size:22px;color:#4BA7C6;padding:0 0 15px 0;margin: 0;font-weight: 500;}
footer .f_in p.f_sub{font-size:16px;color:#10627C;font-weight: 400 !important;}
footer .f_in_in1 li{font-size:14px;padding:0 0 10px 10px;}
footer .f_in_in1 .f_in_in1_1{float:left;width:270px;}
footer .f_in_in1 .f_in_in1_2{float:left;width:290px;}
footer .f_in_in1 .f_in_in1_3{float:left;width:270px;}
footer .f_in_in1 .f_in_in1_4{float:right;}

footer .f_in_in1 ul > li > a{overflow: hidden;display:block;color:#4BA7C6 !important;}
footer .f_in_in1 ul > li > a span{position: relative;display: inline-block;-moz-transition: 0.3s;-o-transition: 0.3s;-webkit-transition: 0.3s;transition: 0.3s;}

footer .f_in_in1 ul > li > a span:before {position: absolute;top: 100%;left:0;content: attr(data-hover);-moz-transform: translate3d(0, 0, 0);-ms-transform: translate3d(0, 0, 0);-webkit-transform: translate3d(0, 0, 0);transform: translate3d(0, 0, 0);}
footer .f_in_in1 ul > li:hover a span {-moz-transform: translateY(-100%);-ms-transform: translateY(-100%);-webkit-transform: translateY(-100%);transform: translateY(-100%);}

footer .f_in_in1 .f_in_in1_4 .sign-up{background:#4BA7C6;border-radius:100px;text-align:center;box-shadow: 2px 2px 8px 0px rgba(0,0,0,0.15);transition: all 0.8s;}
footer .f_in_in1 .f_in_in1_4 .sign-up a span{color:#fff;width:155px;display:block;line-height:50px;transition: all 0.8s;}
footer .f_in_in1 .f_in_in1_4 .sign-up a span:before {width:155px;}
footer .f_in_in1 .f_in_in1_4 .sign-up:hover {box-shadow:0 0 0 0 rgba(0,0,0,0);}
footer .f_in_in1 .f_in_in1_4 .sign-up:hover a span {-moz-transform: translateY(0);-ms-transform: translateY(0);-webkit-transform: translateY(0);transform: translateY(0);box-shadow: 2px 2px 7px 2px rgba(0, 0, 0, 0.2) inset;border-radius:100px;}

footer .f_in_in1 .f_in_in1_4 .contact{background:#fff;border:2px solid #4BA7C6;border-radius:100px;margin:25px 0 0 0;text-align:center;box-shadow: 2px 2px 8px 0px rgba(0,0,0,0.15);transition: all 0.8s;}
footer .f_in_in1 .f_in_in1_4 .contact a span{color:#4BA7C6;width:100%;display:block;line-height:50px;transition: all 0.8s;}
footer .f_in_in1 .f_in_in1_4 .contact:hover {box-shadow:0 0 0 0 rgba(0,0,0,0);}
footer .f_in_in1 .f_in_in1_4 .contact:hover a span {-moz-transform: translateY(0);-ms-transform: translateY(0);-webkit-transform: translateY(0);transform: translateY(0);box-shadow: 2px 2px 7px 2px rgba(0, 0, 0, 0.2) inset;border-radius:100px;}

footer .f_in_in2 ul{display:table;margin:0 auto;padding:20px 0 0 0;}
footer .f_in_in2 .f_logo1{display:table-cell;width:140px;vertical-align: middle;}
footer .f_in_in2 .f_logo2{display:table-cell;width:125px;padding:0 35px;vertical-align: middle;}
footer .f_in_in2 .f_logo3{display:table-cell;width:34px;}

footer .f_in_in3{display:flex;justify-content: center;padding:30px 0 0 0;}
footer .f_in_in3 small{font-size:12px;color:#4BA7C6;}
footer .f_in_in3 ul{display:table;font-size:12px;padding:0 0 0 60px;}
footer .f_in_in3 ul li{display:table-cell;padding:0 30px 0 0;}
footer .f_in_in3 ul li a{text-decoration:underline;color:#4BA7C6 !important;}
