.assetMarks {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 6px;
}

.assetMarks span {
  display: inline-block;
  height: 19px;
  line-height: 17px;
  border-radius: 10px;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  padding: 3px 12px;
  margin-bottom: 6px;
}

.assetMark-new {
  background: #D6A985;
}

.assetMark-featured {
  background: #A5A3CC;
}

.assetMark-updated {
  background: #B8CC7A;
}
