.pageNavi{
  margin-top: 30px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.pageNavi a{
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333 !important;
  text-decoration: none !important;
  font-size: 18px;
  font-weight: bold;
}

.pageNavi .pageNaviMain{
  display: flex;
}

.pageNavi .pageNaviMainC{
  display: flex;
}

.pageNavi .pageNaviArrow{
  width:64px;
  display: flex;
  justify-content: center;
  font-size: 18px;
}

.pageNavi .pageNaviArrow a,.pageNavi .pageNaviArrow span{
  width:64px;
  height: 30px;
  position: relative;
}

.pageNavi .pageNaviArrowL a::after,.pageNavi .pageNaviArrowL span::after{
  content: "";
  position: absolute;
  left: 30px;
  top: 50%;
  width: 14px;
  height: 14px;
  border-top: 3px solid #64C8D2;
  border-right: 3px solid #64C8D2;
  transform: translateY(-50%) rotate(-135deg);
  pointer-events: none;
}


.pageNavi .pageNaviArrowR a::after,.pageNavi .pageNaviArrowR span::after{
  content: "";
  position: absolute;
  right: 30px;
  top: 50%;
  width: 14px;
  height: 14px;
  border-bottom: 3px solid #64C8D2;
  border-left: 3px solid #64C8D2;
  transform: translateY(-50%) rotate(-135deg);
  pointer-events: none;
}

.pageNavi .pageNaviDivider{
  width:40px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
}

.pageNavi .pageNaviMain a{
  width:36px;
  height: 30px;
}

.pageNavi .pageNaviMain span{
  margin: 0 5px;
  width:30px;
  height: 30px;
  background-color: #64C8D2;
  color: white;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold; 
}

.pageNaviShow--false{
  display: none !important;
}