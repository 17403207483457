.appErrorMessages .errorMessage {
  box-sizing: border-box;
  animation: messageBar 300ms 0s ease;
  border: none;
  margin-bottom: 16px;
  overflow: hidden;
}

.appErrorMessages h3 {
  box-sizing: border-box;
  position: relative;
  border-radius: 20px 20px 0 0;
  border: solid #E65022 3px;
  border-bottom: none;
  margin: 0;
  background: #E65022;
  color: #FFFFFF;
  height: 56px;
  padding: 15px;
}

.appErrorMessages h3 > img {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translate(0, -50%);
  cursor: pointer;
}

.appErrorMessages p {
  box-sizing: border-box;
  position: relative;
  border-radius: 0 0 20px 20px;
  margin: 0;
  background: #FFFFFF;
  color: #E65022;
  padding: 15px;
  border: solid #E65022 3px;
  border-top: none;
}

.appErrorMessages ul {
  color: #333333;
}

.appErrorMessages .errorMessage.dismiss {
  opacity: 0;
  transition: opacity 200ms 0s ease;
}

.appErrorMessages .errorMessage.hide {
  display: none;
}
