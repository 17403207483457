.contsLogin{}

.contsLogin .inputBox{
  padding-top: 32px;
  padding-bottom: 30px;
}

.contsLogin .inputBox2{
  padding-bottom: 50px;
}

.contsLogin .button{
  margin: 0 auto;
}

.contsLogin .forgotLink{
  margin-top: 20px;
  margin-bottom: 35px;
  text-align: center;
  font-size: 14px;
  color:#A0A0A0 !important;
  font-weight: bold;
  letter-spacing: 1px;
}

.contsLogin .loginHr{
  width: 100%;
  margin: 5px 0 49px;
  opacity: 0.3;
}
