.ncdFiles{
  width: 100%;
}

.ncdFilesHeader{
  display: flex;
  padding-top: 27px;
  padding-bottom: 10px;
  align-items: center;
}

.ncdFilesHeader h3{
  font-size: 20px;
  color: #64C8D2;
}

.ncdFilesHeader p{
  font-size: 14px;
  margin: 0 0 0 22px;
  padding: 0 0 0;
  font-weight: normal;
}

.ncdFiles ul{
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
}

.ncdFiles ul li{
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  margin: 12px 1%;
  width: 48%;
}
.ncdFiles ul li:first-child{
  border-right: 1px #A0A0A0 solid;
}

.ncdFiles ul li p{
  color: #333333;
  font-weight: normal;
  font-size: 16px;
  margin: 0;
  padding: 0 0 0;
}

.ncdFiles ul li .ncdFilesBtn{
  padding-right: 20px;
  display: flex;
  justify-content: flex-end;
}

.ncdFiles ul li .ncdFilesBtn button{
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  font-size: 16px;
}

.ncdFiles ul li .ncdFilesBtn button::after{
  content:url("./assets/icon_download.svg");
  margin: 3px 0 0 9px;
}
.contsAssetEdit .ncdFiles ul,
.contsAssetAdd .ncdFiles ul{
  display: block;
}
.contsAssetEdit .ncdFiles ul li,
.contsAssetAdd .ncdFiles ul li{
  width: auto;
}
.contsAssetEdit .ncdFiles ul li:first-child,
.contsAssetAdd .ncdFiles ul li:first-child{
  border: none;
}

.contsAssetEdit .ncdFiles ul li .ncdFilesBtn .button:last-child,
.contsAssetAdd .ncdFiles ul li .ncdFilesBtn .button:last-child{
  margin-left: 20px;
}
.contsAssetEdit .ncdFiles ul li .ncdFilesBtn .button:last-child button::after,
.contsAssetAdd .ncdFiles ul li .ncdFilesBtn .button:last-child button::after{
  content:url("./assets/icon_dustbox.svg");
  margin: 5px 0 0 9px;
}

.ncdFilesFooter{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-radius: 27px;
}

.ncdFilesFooter p{
  padding: 0;
  margin: 0;
  font-size: 14px;
  padding-left: 20px;
  font-weight: normal;
}

.ncdFilesFooter .button{
  color: white;
  font-size: 16px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
