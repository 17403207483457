header {box-shadow: 0px 0px 14px 0px rgba(0,0,0,0.1);background:#F5F8F8;position:relative;z-index:11;font-family: 'Poppins', sans-serif;}
header.lang-ja{font-family: 'Poppins', "游ゴシック", YuGothic, "游ゴシック体", "ヒラギノ角ゴ Pro W3", "メイリオ", sans-serif !important; }
header .h_in{width:1200px;margin:0 auto;padding:0 0;display:flex;align-items:center;justify-content: space-between;}
header .h_in h1{width:140px;margin:0 80px 0 0;}
header .h_in .gnavbox ul {display:flex;align-items:center;*zoom: 1;-moz-box-sizing: border-box;-webkit-box-sizing: border-box;box-sizing: border-box;text-align: center;padding:15px 0; margin:0;}
header .h_in .gnavbox ul:before, header .h_in .gnavbox ul:after {content: "";display: table;}
header .h_in .gnavbox ul:after {clear: both;}
header .h_in .gnavbox ul > li {margin:0 0 0 30px;color: #4BA7C6;font-weight: 500;}
header.lang-ja .h_in .gnavbox ul > li {font-weight: 600;}
header .h_in .gnavbox ul > li > a{overflow: hidden;display:block;}
header .h_in .gnavbox ul > li:first-child{margin:0;}
header.lang-ja .h_in .gnavbox ul > li:first-child > a{height:28px;}
header .h_in .gnavbox ul > li.lang{border:1px solid #4BA7C6;background:#fff;border-radius:50px;width:50px;margin:0 0 0 25px;}
header .h_in .gnavbox ul > li > a span{color:#4BA7C6;position: relative;display: inline-block;-moz-transition: 0.3s;-o-transition: 0.3s;-webkit-transition: 0.3s;transition: 0.3s;}
header .h_in .gnavbox ul > li.drug-candidate{border-bottom:1px solid #4BA7C6;}
header .h_in .gnavbox ul > li.lang > a span{width:100%;}
header .h_in .gnavbox ul > li.lang > a span:before{width:100%;}
header .h_in .gnavbox ul > li > a:hover {opacity: 1;}
header .h_in .gnavbox ul > li > a span:before {position: absolute;top: 100%;left:0;content: attr(data-hover);-moz-transform: translate3d(0, 0, 0);-ms-transform: translate3d(0, 0, 0);-webkit-transform: translate3d(0, 0, 0);transform: translate3d(0, 0, 0);}
header .h_in .gnavbox ul > li:hover a span {-moz-transform: translateY(-100%);-ms-transform: translateY(-100%);-webkit-transform: translateY(-100%);transform: translateY(-100%);}
header .h_in .gnavbox ul > li.sign-in{border-left:2px solid #4BA7C6;padding:0 0 0 30px;}
header .h_in .gnavbox ul > li.sign-up{background:#4BA7C6;border-radius:100px;margin:0 0 0 25px;box-shadow: 2px 2px 8px 0px rgba(0,0,0,0.15);transition: all 0.8s;}
header .h_in .gnavbox ul > li.sign-up:hover{box-shadow:0 0 0 0 rgba(0,0,0,0);}
header .h_in .gnavbox ul > li.sign-up a span{color:#fff;width:155px;display:block;line-height:50px;transition: all 0.8s;}
header .h_in .gnavbox ul > li.sign-up:hover a span {-moz-transform: translateY(0);-ms-transform: translateY(0);-webkit-transform: translateY(0);transform: translateY(0);box-shadow: 2px 2px 7px 2px rgba(0, 0, 0, 0.2) inset;border-radius:100px;}
header .h_in .gnavbox ul > .langDummy{width:75px;}

header .h_in .gnavbox ul > li{position: relative;}
header .h_in .gnavbox ul > li > div {display:none;}
header .h_in .gnavbox ul > li:hover {z-index:0;}
header .h_in .gnavbox ul > li:hover > .droplist {z-index:9;display:block;position:absolute;top:1.5em;left:0;padding:10px 0 0 0;white-space: nowrap;text-align:left;}
header .h_in .gnavbox ul > li:hover > .droplist > div{padding:20px 25px;background:#fff;border-radius:15px;box-shadow: 3px 3px 4px 0px rgba(0,0,0,0.1);font-size:14px;}
header .h_in .gnavbox ul > li:hover > .droplist > div > a{padding:5px 0;display:block;color:#222    !important;-webkit-transition: all 0.3s linear;-moz-transition: all 0.3s linear;-o-transition: all 0.3s linear;transition: all  0.3s linear;font-weight: 400 !important;}
header .h_in .gnavbox ul > li:hover > .droplist > div > a:hover{color:#4BA7C6 !important;}
header .h_in .gnavbox ul > li:hover > .droplist > div > p{color:#10627C; font-weight: 600; margin-left: -10px;}
