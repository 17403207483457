.contsCreateAccount{}

.contsCreateAccount p.read{
  font-size: 16px;
  padding-top: 16px;
  padding-bottom: 54px;
  line-height: 180%;
  letter-spacing: 1px;
}

.contsCreateAccount .inputBoxes{
  grid-template-columns: 300px 1fr;
  margin-top: 26px;
  margin-bottom: 30px;
  align-content: space-between;
}

.contsCreateAccount .inputBoxes .input1LineBox,.contsCreateAccount .inputBoxes .checkBoxGroup{
  margin-bottom: 33px;
}

.contsCreateAccount .inputBoxes .inputBoxLabel{
  display: block;
  padding-bottom: 12px;
  color: #4BA7C6;
  font-size: 16px;
  letter-spacing: 1px;
  font-weight: bold;
}

.contsCreateAccount .inputBoxes .inputBoxLR{
  display: flex;
  justify-content: space-between;
  margin-bottom: 33px;
}

.contsCreateAccount .inputBoxes .inputBoxLR .inputSelect,
.contsCreateAccount .inputBoxes .inputBoxLR .input1LineBox{
  margin-right: 0;
}

.contsCreateAccount .TermBox{
  padding-bottom: 30px;
}

.contsCreateAccount .TermBoxTerm{
  border: 0;
  border-radius: 15px;
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  background-color: transparent;
  box-shadow: rgb(0 0 0 / 0) 0px 0px 0px 1px inset;
  font-size: 14px;
  padding: 25px 16px 25px 30px;
  box-sizing: border-box;
  position: relative;
  color: #333;
  background-color: white;
  letter-spacing: 1px;
}

.contsCreateAccount .TermBoxTerm dt{margin:10px 0;}
.contsCreateAccount .TermBoxTerm dd{margin:10px 10px 20px}
.contsCreateAccount .TermBoxTerm ol, .contsCreateAccount .TermBoxTerm ul{margin:5px;}
.contsCreateAccount .TermBoxTerm ol li{margin:5px; text-indent: -1.4em;}
.contsCreateAccount .TermBoxTerm ul li{margin: 5px;list-style: disc;text-indent: 0;}
.contsCreateAccount .TermBoxTerm .indent{text-indent: -1.5em; margin-left: 22px;}
.contsCreateAccount .TermBoxTerm .signature{margin:10px 0; text-align: right;}
.contsCreateAccount .TermBoxTerm .attachment{margin:75px 0 0;}

.contsCreateAccount .TermBox .TermBoxLabel{
  display: block;
  padding-bottom: 12px;
  color: #4BA7C6;
  font-size: 16px;
  letter-spacing: 1px;
  font-weight: bold;
  margin-top: -30px;
}

.contsCreateAccount .TermBoxTerm p{
  height: 96px;
  overflow-y: auto;
  margin: 0;
  padding: 0;
  line-height: 180%;
}

.contsCreateAccount .TermBox .checkBoxGroup{
  margin: 23px 0 0 5px;
}

.contsCreateAccount .TermBox .createAccountSubmit{
  margin: 30px auto 0;
  text-align: center;
}
.contsCreateAccount .TermBox .checkBoxGroupBox label::after{
  content:'*';
  color: #E65022;
}

.createAccountSubmit .button{
  margin: 0 auto;
}

.invitationCompany{
  border-radius: 100px;
    display: block;
    padding: 10px 30px;
    width: 100%;
    background: #D6D6D6;
    color: #fff;
    letter-spacing: 1px;
    margin-top: 0;
    margin-bottom: 33px;
    font-weight: normal;
    font-size: 16px;
}

/*InvitationLoginPanel用*/
.contsCreateAccount p{
  font-size: 16px;
  font-weight: 500;
}
.contsCreateAccount h3{
  font-weight: 700;
  font-size: 36px;
  color: #4BA7C6;
  line-height: 1.4;
  margin: 20px 0;
}
.contsCreateAccount .inputBox{
  padding-top: 32px;
  padding-bottom: 30px;
}

.contsCreateAccount .inputBox2{
  padding-bottom: 50px;
}
