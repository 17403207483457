.searchTab {display: flex;justify-content: center;cursor: pointer;width:100%;margin:22px auto 0;}
.searchTab .tab {
  width:calc((100% - 40px)/2);
  max-width: 410px;
  min-width: 200px;
  margin: 0 9px;
  height: 45px;line-height: 45px;text-align: center;color: #fff;background:#64C8D2;border-radius:15px 15px 0 0;box-shadow: 0 -2px 2px 0 rgba(0,0,0,0.07); font-size: 16px;
}
.searchTab .tab.tabActive--true {background:#E6F0F2;color: #006181;  z-index: 2;}
.searchTab .tab a{
  width: 100%;
  height: 100%;
  color: white !important;
  text-decoration: none !important;
  display: block;
}
.searchTab .tab.tabActive--true  a{
  color: #006181 !important;
}