.asideList {
  list-style-type: none;
  padding-bottom: 15px;
  margin: 0;
  position: relative;
  height: 56px;
}

.asideList a,.asideList button{
  position: relative;
  width:240px;
  height: 44px;
  display: flex;
  align-items: center;
  padding: 0 12px 0 38px;
  font-size: 16px;
  color: black;
  border-radius: 22px;
  box-shadow: none;
  box-sizing: border-box;
  color: white !important;
}

.asideListIndent--true a,.asideListIndent--true button{
  width:162px;
  padding: 0 26px 0 52px;
}

.asideList a:hover,.asideList button:hover{
  background-color:#49C0CE;
}

.asideList--true a,.asideList--true button{
  background-color: #64C8D2;
  position: absolute;
  top:0;
  left: 0;
  z-index: 1;
}

.asideList--true::before{
  content: '';
  position: absolute;
  top:-2px;
  left: -2px;
  width: 240px;
  height: 44px;
  border-radius: 22px;
  background: #98f2ff;
  filter: blur(2px);
}

.asideList--true::after{
  content: '';
  position: absolute;
  top:2px;
  left: 2px;
  width: 240px;
  height: 44px;
  border-radius: 22px;
  background: #41A5AF;
  filter: blur(2px);
}

.asideList span{
  display: flex;
  width:26px;
  height: 26px;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 9px;
  right: 10px;
  font-weight: bold;
  background-color: white;
  color: #64C8D2;
  border-radius: 13px;
  box-sizing: border-box;
}
