.chatSend {
  display: flex;
  width: calc(100vw - 274px);
  /*height: 102px;*/
  justify-content: space-between;
  align-items: center;
  position: fixed;
  bottom: 0;
  right: 0;
  box-sizing: border-box;
  padding: 15px 30px;
  background-color: white;
  background: #f5f5f5;
  box-shadow: 3px 0px 10px rgba(0, 0, 0, 0.1);
}

/*
.chatSend {
  display: flex;
  margin-top: 60px;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  bottom: 10px;
  left: 350px;
  right: 50px;
}
*/

.chatSend .button,.chatSend button {
  width: 80px;
  height: 38px;
  font-size: 16px;
}

.chatSend .button{
  background-color: #64C8D2;
}

.chatSend button{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  line-height: 100%;
}

.chatSend textarea {
  border-radius: 27px;
  width: calc(100% - 105px);
  height: 42px;
  border-radius: 21px;
  background: #fff;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.06);
  padding-top: 11px;
  padding-bottom: 11px;
}
