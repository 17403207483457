.buttonBack {
}

.buttonBack button {
  color: white;
  width: 26px;
  height: 26px;
  background: #006181;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none !important;
  border: none;
  box-sizing: border-box;
  position: relative;
}

.buttonBack button::after{
  content: "";
  position: absolute;
  left: 10px;
  top: 50%;
  width: 9px;
  height: 9px;
  border-top: 2px solid white;
  border-right: 2px solid white;
  transform: translateY(-50%) rotate(-135deg);
  pointer-events: none;
}

.buttonBack button:hover {
  opacity: 0.5;
}

h1 .buttonBack {
  margin-right: 1px;
  transform: translateX(-13px);
}