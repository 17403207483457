.debugMenu {
  position: fixed;
  bottom: 24px;
  right: 24px;
  width: 32px;
  height: 32px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);
  background: gray;
  border-radius: 16px;
  user-select: none;
}

.debugMenu:before {
  content: "▲";
  font-weight: bold;
  font-size: 16px;
  width: 16px;
  height: 16px;
  text-align: center;
  line-height: 16px;
  top: 8px;
  left: 8px;
  position: absolute;
  color: white;
  transition: transform 300ms 0s ease;
}

.debugMenu .debugMenuSub {
  box-sizing: border-box;
  display: block;
  position: absolute;
  bottom: 40px;
  right: -4px;
  overflow: hidden;
  border-radius: 8px;
  max-height: 0;
  background: #1ea7fd;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.5);
  transition: max-height 300ms 0s ease;
}

.debugLink {
  display: block;
  text-align: left;
  color: white;
  cursor: pointer;
  min-width: 120px;
  white-space: nowrap;
  margin: 0 12px 4px;
}

.debugLink:first-child {
  margin-top: 8px;
}

.debugLink:last-child {
  margin-bottom: 8px;
}

.debugLink:hover {
  text-decoration: underline;
}

.debugMenu:active {
  box-shadow: none;
}

.debugMenu:focus:before {
  transform: rotate(-180deg);
}

.debugMenu:focus {
  background: #1ea7fd;
}

.debugMenu:focus .debugMenuSub {
  max-height: 80vh;
}
