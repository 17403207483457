.assetMarkChecks {
  display: flex;
  flex-wrap: wrap;
  padding-top: 4px;
}

.assetMarkCheckBox{
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.assetMarkChecks label{
  font-size: 16px;
  padding: 0 30px 0 0;
  cursor: pointer;
}

.assetMarkCheckBox input[type="checkbox"]{display: none;}
.assetMarkCheckBox span{padding-left: 34px;position:relative;font-weight: normal; }
.assetMarkCheckBox span::before{content: "";display: block;position: absolute;top: 0;left: 0;width: 20px;height: 20px;border-radius: 5px;background:#fff;border: 1px solid #a0a0a0;}
.assetMarkCheckBox input[type="checkbox"]:checked + span::after{content: "";display: block;position: absolute;top: 0px;left: 8px;width: 9px;height: 15px;transform: rotate(40deg);border-bottom: 3px solid #333;border-right: 3px solid #333;}
.assetMarkCheckBox span p{display:inline;}

.layout-white .assetMarkCheckBox span{
  letter-spacing: 1px;
  font-weight: bold;
}

.layout-white .assetMarkCheckBox span::before{
  width: 24px;
  height: 24px;
  border-radius: 3px;
  border: 2px solid #4BA7C6;
}

.layout-white .assetMarkCheckBox input[type="checkbox"]:checked + span::after{
  top: 0px;left: 10px;width: 12px;height: 18px;
}
