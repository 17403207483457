.input1LineBox {
  position: relative;
}

.input1LineBox .input1LineEye{
  position: absolute;
  right:8px;
  bottom:4px;
  display: block;
  width:40px;
  height: 40px;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url("./assets/icon_eye_open.svg");
  cursor: pointer;
}

.input1LineBox.input1LineBoxOpen--false .input1LineEye{
  background-image: url("./assets/icon_eye_close.svg");
}

.input1LineBox label{
  display: block;
  font-size: 14px;
  color: #64C8D2;
  font-weight: bold;
}

.input1LineBox input[type="password"]{
  letter-spacing: 3px;
}

.input1LineBox input[type="password"]::placeholder{
  letter-spacing: 0;
}

.input1Line {
  background:#fff;border-radius:100px;display:block;padding:15px 30px;width:100%;color:#333;
  font-size: 16px;
}
.input1Line::placeholder{
  color: #CCC;
}
.input1Line:-webkit-autofill {
  box-shadow: 0 0 0px 1000px white inset;
}

.input1Line--search{
  background-image: url("./assets/icon-search.svg");
  background-repeat: no-repeat;
  background-position: left 15px top 50% ;
  padding: 12px 10px 12px 45px;
}

.layout-white .input1LineBox label{
  color: #4BA7C6;
  font-size: 16px;
  letter-spacing: 1px;
  padding-bottom: 12px;
}

.card .originatorAddMembers .input1Line,
.card .tableGrayThVertical .input1Line,
.contsMemberInvite .input1Line{
  box-shadow: 0 0 0px 1px #CCC;
  padding: 10px 20px;
}

.contsProfileEdit .card .tableGrayThVertical .input1Line {
  margin-left: -20px;
  margin-right: 15px;
}

.contsMemberInvite .input1LineBox label{
  padding-bottom: 5px;
}